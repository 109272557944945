import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { OnChange } from 'react-final-form-listeners';
import {
  composeValidators,
  email,
  link,
  maxLength,
  minLength,
  number,
  renderField,
  required
} from '../../helpers/form-validations';
import {
  getAllBusinessLicenseType,
  getAllCategories,
  getAllCities,
  getAllSubCategories
} from '../../utils/API';
import i18n from '../../i18n';
import Map from './Map';

const BusinessInformation = forwardRef(
  ({ onFormSubmit, profileDetails, isProfile = false, locale }, ref) => {
    const { t } = useTranslation();
    const businessNature = [t('signUp.businessNature.food'), t('signUp.businessNature.grocery')];
    const [selectBusinessNature, setSelectBusinessNature] = useState(businessNature[0]);
    const [cities, setAllCities] = useState([]);
    const [businessLicenseTypes, setAllBusinessLicenseTypes] = useState([]);
    const [categories, setAllCategories] = useState([]);
    const [subCategories, setAllSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState([]);
    const submitBtnRef = useRef();
    const [halalStatus, setHalalStatus] = useState('0');
    const lat = isProfile ? parseFloat(profileDetails.business.lat) : 3.140853;
    const lng = isProfile ? parseFloat(profileDetails.business.lng) : 101.693207;

    useEffect(() => {
      window.loadSelectPicker('.sub-category-select', {
        noneSelectedText: i18n.t('validation.nothingSelected')
      });
    }, [locale]);

    useEffect(() => {
      window.$('.sub-category-select').selectpicker('refresh');
    }, [locale]);

    const getCategories = async (type) => {
      const categoriesRes = await getAllCategories(type);
      setAllCategories(categoriesRes.data);
      window.setTimeout(() => {
        window.loadSelectPicker('.category-select');
        window.$('.category-select').selectpicker('refresh');
        window.$('.sub-category-select').selectpicker('refresh');
      }, 200);
    };

    const getSubCategories = async (type) => {
      const subCategoriesRes = await getAllSubCategories({ type });
      setAllSubCategories(subCategoriesRes.data);
      setTimeout(() => {
        window.loadSelectPicker('.sub-category-select', {
          noneSelectedText: i18n.t('validation.nothingSelected')
        });
        window.$('.sub-category-select').selectpicker('refresh');
      }, 200);
    };

    const getBusinessLicenseType = async () => {
      const businessLicenseTypeRes = await getAllBusinessLicenseType();
      setAllBusinessLicenseTypes(businessLicenseTypeRes.data);
      setTimeout(() => {
        window.loadSelectPicker('.businessLicenseType-select');
      }, 200);
    };

    const getCities = async () => {
      const citiesRes = await getAllCities();
      setAllCities(citiesRes.data);
      setTimeout(() => {
        window.loadSelectPicker('.city-select');
      }, 200);
    };

    const onSubmit = (formData) => {
      if (formData.hasOwnProperty('city')) {
        delete formData.city;
      }
      if (formData.hasOwnProperty('address')) {
        delete formData.address;
        delete formData.state;
        delete formData.country;
        delete formData.x;
        delete formData.y;
        delete formData.event;
      }
      onFormSubmit({
        ...formData,
        halalStatus,
        lat: formData.lat.toString(),
        lng: formData.lng.toString(),
        subCategoryId: !_.isEmpty(formData.subCategoryId) ? formData.subCategoryId.join(',') : '',
        addressLine2: '',
        orderLink: formData.hasOwnProperty('orderLink') ? formData.orderLink : ''
      });
    };

    useImperativeHandle(ref, () => ({
      onParentSubmit() {
        submitBtnRef.current.click();
      }
    }));

    useEffect(() => {
      getCategories(
        locale === 'bm'
          ? selectBusinessNature === 'Makanan'
            ? 'food'
            : 'grocery'
          : selectBusinessNature
      );
      getSubCategories(
        locale === 'bm'
          ? selectBusinessNature === 'Makanan'
            ? 'food'
            : 'grocery'
          : selectBusinessNature
      );

      getCities();
      getBusinessLicenseType();
      window.loadSelectPicker('.halal-status-select');
      window.loadSelectPicker('.businessNature');
    }, [selectBusinessNature]);

    useEffect(() => {
      window.$('.halal-status-select').selectpicker('refresh');
      window.$('.businessNature').selectpicker('refresh');
    }, [categories, subCategories, selectBusinessNature]);

    const onClickBusinessNature = (selectedBusinessNatureType) => {
      setSelectBusinessNature(selectedBusinessNatureType);
      getCategories(selectedBusinessNatureType);
      getSubCategories(selectedBusinessNatureType);
    };

    useEffect(() => {
      if (isProfile) {
        setSelectedSubCategory(profileDetails.business.subCategoryId.split(','));
        setHalalStatus(profileDetails.business.halalStatus.toString());
      }
    }, []);

    const onPlaceSelected = (place, form, isClick) => {
      if (isClick) {
        const city = _.find(cities, ['title', place.city]);
        if (!_.isEmpty(city)) {
          form.mutators.setValue('cityId', city.id);
          setTimeout(() => {
            window.$('.city-select').selectpicker('refresh');
          }, 100);
        }
        form.mutators.setValue('addressLine1', place.address);
        Object.keys(place).map((key) => {
          form.mutators.setValue(key, place[key]);
        });
      }
    };

    return (
      <div className="information-row merchantinfosection" id="infobusiness">
        <h2 className="section-title">{t('signUp.sideMenu.businessInformation')}</h2>
        <Form
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            }
          }}
          onSubmit={onSubmit}
          initialValues={
            isProfile
              ? {
                  ownerName: profileDetails.business.ownerName,
                  businessName: profileDetails.business.businessName,
                  licenseNumber: profileDetails.business.licenseNumber,
                  licenseTypeId: profileDetails.business.licenseTypeId,
                  addressLine1: profileDetails.business.addressLine1,
                  addressLine2: profileDetails.business.addressLine2,
                  cityId: profileDetails.business.cityId,
                  lat: profileDetails.business.lat,
                  lng: profileDetails.business.lng,
                  posCode: profileDetails.business.posCode,
                  contactInfo: profileDetails.business.contactInfo,
                  whatsappNumber: profileDetails.business.whatsappNumber,
                  businessEmail: profileDetails.business.businessEmail,
                  businessNature: profileDetails.business.businessNature,
                  categoryId: profileDetails.business.categoryId,
                  orderLink: profileDetails.business.orderLink,
                  halalStatus: profileDetails.business.halalStatus.toString(),
                  description: profileDetails.business.description,
                  email: profileDetails.email,
                  phone: profileDetails.phone
                }
              : {}
          }
          validate={(values) => {
            const errors = {};
            if (!isProfile) {
              if (values.confPassword !== values.password) {
                errors.confPassword = `${t('signUp.businessInformation.mustWatch')}`;
              }
            }
            return errors;
          }}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <div className="row mb-5">
                <div className="col-md-8">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.ownerName')}</label>
                    <Field
                      className="form-control"
                      name="ownerName"
                      type="text"
                      placeholder={t('signUp.businessInformation.ownerName')}
                      validate={composeValidators(required)}>
                      {renderField}
                    </Field>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.ownerIC')}</label>
                    <Field
                      className="form-control"
                      name="ownerIC"
                      type="text"
                      placeholder={t('signUp.businessInformation.ownerIC')}
                      validate={composeValidators(required)}>
                      {renderField}
                    </Field>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.emailAdd')}</label>
                    <Field
                      disabled={isProfile}
                      className="form-control"
                      name="email"
                      type="text"
                      placeholder={t('signUp.businessInformation.emailAdd')}
                      validate={composeValidators(required, email)}>
                      {renderField}
                    </Field>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.phoneNumber')}</label>
                    <div className="row country-code">
                      <div className="col-3">
                        <span className="form-control">+60</span>
                      </div>
                      <div className="form-number col-9">
                        <Field
                          disabled={isProfile}
                          className="form-control"
                          name="phone"
                          type="text"
                          placeholder={t('signUp.businessInformation.phoneNumber')}
                          validate={composeValidators(required, number)}>
                          {renderField}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.pass')}</label>
                    <Field
                      className="form-control"
                      name="password"
                      type="password"
                      placeholder={t('signUp.businessInformation.pass')}
                      validate={
                        isProfile
                          ? composeValidators(minLength(8))
                          : composeValidators(required, minLength(8))
                      }>
                      {renderField}
                    </Field>
                  </div>
                </div>

                {!isProfile && (
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>{t('signUp.businessInformation.cPass')}</label>
                      <Field
                        className="form-control"
                        name="confPassword"
                        type="password"
                        placeholder={t('signUp.businessInformation.cPass')}
                        validate={composeValidators(required, minLength(8))}>
                        {renderField}
                      </Field>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.nameOfB')}</label>
                    <Field
                      className="form-control"
                      name="businessName"
                      type="text"
                      placeholder={t('signUp.businessInformation.nameOfB')}
                      validate={composeValidators(required)}>
                      {renderField}
                    </Field>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.bLicenseNo')}</label>
                    <Field
                      className="form-control"
                      name="licenseNumber"
                      type="text"
                      placeholder={t('signUp.businessInformation.bLicenseNo')}>
                      {renderField}
                    </Field>
                  </div>
                </div>
                {!_.isEmpty(businessLicenseTypes) ? (
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>{t('signUp.businessInformation.bLicenseType')}</label>
                      <Field
                        component="select"
                        name="licenseTypeId"
                        id="licenseTypeId"
                        className="businessLicenseType-select boot-select"
                        initialValue={
                          isProfile
                            ? businessLicenseTypes[profileDetails.business.licenseTypeId]
                            : businessLicenseTypes.length > 0
                            ? businessLicenseTypes[0].id
                            : ''
                        }>
                        {businessLicenseTypes.map((businessLicenseType) => (
                          <option key={businessLicenseType.id} value={businessLicenseType.id}>
                            {businessLicenseType.title}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-4" />
                )}
                <div className="col-md-12 py-5">
                  <div className="form-group">
                    <Map
                      center={{ lat, lng }}
                      location={isProfile ? profileDetails.business.addressLine1 : ''}
                      height="300px"
                      zoom={14}
                      onPlaceSelected={(place, isClick) => onPlaceSelected(place, form, isClick)}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.bAdd1')}</label>
                    <Field
                      className="form-control"
                      name="addressLine1"
                      type="text"
                      placeholder={t('signUp.businessInformation.bAdd1')}
                      validate={composeValidators(required)}>
                      {renderField}
                    </Field>
                  </div>
                </div>
                {!_.isEmpty(cities) ? (
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>{t('signUp.businessInformation.city')}</label>
                      <Field
                        component="select"
                        id="city"
                        name="cityId"
                        className="city-select boot-select"
                        initialValue={
                          isProfile
                            ? cities[profileDetails.business.cityId]
                            : cities.length > 0
                            ? cities[0].id
                            : ''
                        }>
                        {cities.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.title}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-4" />
                )}
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.posCode')}</label>
                    <Field
                      className="form-control"
                      name="posCode"
                      type="text"
                      placeholder={t('signUp.businessInformation.posCode')}
                      validate={composeValidators(required, number)}>
                      {renderField}
                    </Field>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.bContactInfo')}</label>

                    <div className="row country-code">
                      <div className="col-3">
                        <span className="form-control">+60</span>
                      </div>
                      <div className="form-number col-9">
                        <Field
                          className="form-control"
                          name="contactInfo"
                          type="text"
                          placeholder={t('signUp.businessInformation.bContactInfo')}
                          validate={composeValidators(required, number)}>
                          {renderField}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.wpNumber')}</label>
                    <div className="row country-code">
                      <div className="col-3">
                        <span className="form-control">+60</span>
                      </div>
                      <div className="form-number col-9">
                        <Field
                          className="form-control"
                          name="whatsappNumber"
                          type="text"
                          placeholder={t('signUp.businessInformation.wpNumber')}
                          validate={composeValidators(required, number)}>
                          {renderField}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.bEmail')}</label>
                    <Field
                      className="form-control"
                      name="businessEmail"
                      type="text"
                      placeholder={t('signUp.businessInformation.bEmail')}
                      validate={composeValidators(required, email)}>
                      {renderField}
                    </Field>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.bNature')}</label>
                    <Field
                      component="select"
                      name="businessNature"
                      id="businessNature"
                      className="businessNature boot-select"
                      initialValue={isProfile ? profileDetails.business.businessNature : 'food'}>
                      <option key={t('signUp.businessNature.food')} value="food">
                        {t('signUp.businessNature.food')}
                      </option>
                      <option key={t('signUp.businessNature.grocery')} value="grocery" selected>
                        {t('signUp.businessNature.grocery')}
                      </option>
                    </Field>
                    <OnChange name="businessNature">
                      {(value) => {
                        onClickBusinessNature(value);
                      }}
                    </OnChange>
                  </div>
                </div>

                {!_.isEmpty(categories) ? (
                  <div className="col-md-4" temp={Math.random()}>
                    <div className="form-group">
                      <label>{t('signUp.businessInformation.category')}</label>
                      <Field
                        component="select"
                        name="categoryId"
                        id="category"
                        className="category-select boot-select"
                        initialValue={
                          isProfile
                            ? categories[profileDetails.business.categoryId]
                            : categories.length > 0
                            ? categories[0].id
                            : ''
                        }>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.title}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-4" />
                )}
                {!_.isEmpty(subCategories) ? (
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>{t('signUp.businessInformation.subCategory')}</label>
                      <Field
                        component="select"
                        name="subCategoryId"
                        id="subCategoryId"
                        className="sub-category-select boot-select"
                        defaultValue={selectedSubCategory}
                        multiple>
                        {subCategories.map((subCategory) => (
                          <option key={subCategory.id} value={subCategory.id}>
                            {subCategory.title}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <Field type="hidden" name="subCategoryId" validate={required} className="">
                      {renderField}
                    </Field>
                  </div>
                ) : (
                  <div className="col-md-4" />
                )}
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.orderLink')}</label>
                    <Field
                      className="form-control"
                      name="orderLink"
                      type="text"
                      placeholder={t('signUp.businessInformation.orderLink')}
                      validate={composeValidators(link)}>
                      {renderField}
                    </Field>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.halalStatus')}</label>
                    <div className="align-items-center d-flex">
                      <div className="form-check form-check-inline">
                        <Field
                          name="halalStatus"
                          component="input"
                          type="radio"
                          value="1"
                          id="HalalYes"
                          className="form-check-input"
                          checked={halalStatus !== '0'}
                        />
                        <label className="form-check-label" htmlFor="HalalYes">
                          Yes
                        </label>
                        <OnChange name="halalStatus">
                          {() => {
                            setHalalStatus(halalStatus === '0' ? '1' : '0');
                          }}
                        </OnChange>
                      </div>
                      <div className="form-check form-check-inline">
                        <Field
                          name="halalStatus"
                          component="input"
                          type="radio"
                          value="0"
                          id="HalalNo"
                          className="form-check-input"
                          checked={halalStatus === '0'}
                        />
                        <label className="form-check-label" htmlFor="HalalNo">
                          No
                        </label>
                        <OnChange name="halalStatus">
                          {() => {
                            setHalalStatus(halalStatus === '0' ? '1' : '0');
                          }}
                        </OnChange>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label>{t('signUp.businessInformation.descOfBusiness')}</label>
                    <Field
                      className="form-control"
                      name="description"
                      type="textarea"
                      placeholder={t('signUp.businessInformation.descOfBusiness')}
                      validate={composeValidators(required, maxLength(500))}>
                      {renderField}
                    </Field>
                  </div>
                </div>
                <div className="col-md-4 visually-hidden">
                  <div className="form-group">
                    <button type="submit" ref={submitBtnRef}>
                      {t('signUp.businessInformation.save')}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
);
export default BusinessInformation;
