export const RESET_REDUX = 'redux/RESET';
export const LOADER_SET = 'loader/SET';
export const NOTIFY_SET = 'notify/SET';
export const USER_SET = 'user/SET';
export const LOCALE_SET = 'locale/SET';
export const MERCHANT_FILTER_SET = 'merchantFilter/SET';
export const TERMS_AND_CONDITION_SET = 'termsAndCondition/SET';
export const IS_ADVANCE_SEARCH_SET = 'isAdvanceSearch/SET';
export const CITIES_SET = 'cities/SET';
export const BUSINESS_LICENSE_TYPE_SET = 'businessLicenseTypes/SET';
export const FOOD_TYPE_SET = 'foodTypes/SET';
export const SUB_CATEGORIES_SET = 'subCategories/SET';
export const CATEGORIES_SET = 'categories/SET';
export const CUSTOM_TYPES_SET = 'customTypes/SET';
export const PRIVACY_POLICY_SET = 'privacyPolicy/SET';

export const resetRedux = () => ({
  type: RESET_REDUX
});

export const loaderSet = (state) => ({
  type: LOADER_SET,
  state
});

export const notifySet = (state) => ({
  type: NOTIFY_SET,
  state
});

export const userSet = (state) => ({
  type: USER_SET,
  state
});

export const localeSet = (state) => ({
  type: LOCALE_SET,
  state
});

export const merchantFilterSet = (state) => ({
  type: MERCHANT_FILTER_SET,
  state
});

export const termsAndConditionSet = (state) => ({
  type: TERMS_AND_CONDITION_SET,
  state
});

export const isAdvanceSearchSet = (state) => ({
  type: IS_ADVANCE_SEARCH_SET,
  state
});

export const citiesSet = (state) => ({
  type: CITIES_SET,
  state
});

export const businessLicenseTypesSet = (state) => ({
  type: BUSINESS_LICENSE_TYPE_SET,
  state
});

export const foodTypesSet = (state) => ({
  type: FOOD_TYPE_SET,
  state
});

export const subCategoriesSet = (state) => ({
  type: SUB_CATEGORIES_SET,
  state
});

export const categoriesSet = (state) => ({
  type: CATEGORIES_SET,
  state
});

export const customTypesSet = (state) => ({
  type: CUSTOM_TYPES_SET,
  state
});

export const privacyPolicySet = (state) => ({
  type: PRIVACY_POLICY_SET,
  state
});
