import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as appImages from '../assets/images';
import withNotify from '../redux/Hoc/withNotify';
import withLoader from '../redux/Hoc/withLoader';
import BusinessInformation from '../components/UserForm/BusinessInformation';
import ImageUpload from '../components/UserForm/ImageUpload';
import Menu from '../components/UserForm/Menu';
import SocialMedia from '../components/UserForm/SocialMedia';
import OperationHours from '../components/UserForm/OperationHours';
import SideMenu from '../components/UserForm/SideMenu';
import { getProfile, updateProfile } from '../utils/API';
import withLocale from '../redux/Hoc/withLocale';

const Profile = (props) => {
  const { t } = useTranslation();
  const [profileDetails, setProfileDetails] = useState(null);
  const [businessInfo, setBusinessInfo] = useState(null);
  const businessInfoRef = useRef();
  const [uploadImageInfo, setUploadImageInfo] = useState(null);
  const uploadImageInfoRef = useRef();
  const [menuInfo, setMenuInfo] = useState(null);
  const menuInfoRef = useRef();
  const [operationHourInfo, setOperationHourInfo] = useState(null);
  const operationHourInfoRef = useRef();
  const [socialMediaInfo, setSocialMedia] = useState(null);
  const socialMediaRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      window.loadStickyPart();
      window.loadScrollClick();
    }, 2000);
  }, []);

  const getAllProfileDetails = async () => {
    const { setNotify, setLoader } = props;
    setLoader(true);
    const profileDetailsRes = await getProfile();
    const { data, status, message } = profileDetailsRes;
    if (status) {
      setLoader(false);
      setProfileDetails(data);
    } else {
      setLoader(false);
      setNotify({ message, type: 'error' });
    }
  };
  useEffect(() => {
    getAllProfileDetails();
  }, []);

  useEffect(() => {
    const { setNotify, setLoader } = props;
    if (businessInfo && uploadImageInfo && menuInfo && operationHourInfo && socialMediaInfo) {
      setLoader(true);
      delete businessInfo.otp;
      updateProfile({
        ...businessInfo,
        ...uploadImageInfo,
        ...menuInfo,
        ...operationHourInfo,
        ...socialMediaInfo
      }).then(async (response) => {
        const { status, message } = response;
        if (status) {
          setNotify({ message, type: 'success' });
          await getAllProfileDetails();
        } else {
          setNotify({ message, type: 'error' });
        }
        setLoader(false);
      });
    }
  }, [businessInfo, uploadImageInfo, menuInfo, operationHourInfo, socialMediaInfo]);

  const onSubmit = () => {
    businessInfoRef.current.onParentSubmit();
    uploadImageInfoRef.current.onImageParentSubmit();
    menuInfoRef.current.onMenuParentSubmit();
    operationHourInfoRef.current.onOperationHourParentSubmit();
    socialMediaRef.current.onSocialMediaParentSubmit();
  };

  return (
    <div className="main-section">
      {!_.isEmpty(profileDetails) && (
        <section className="merchantinfo-page">
          <div className="container">
            <div className="merchantinfo-row">
              <div className="merchantinfo-left">
                <SideMenu isConfirmation={false} />
              </div>
              <div className="merchantinfo-right">
                <div className="info-banner">
                  <img src={appImages.infoBanner} alt="image" />
                  {!_.isEmpty(profileDetails) && (
                    <p className="banner-text">
                      {t('bannerText.hello')},{' '}
                      <span>
                        {t('bannerText.welcome')} {profileDetails.business.ownerName}{' '}
                        {t('bannerText.back')}
                      </span>
                    </p>
                  )}
                </div>
                <BusinessInformation
                  {...props}
                  ref={businessInfoRef}
                  onFormSubmit={(data) => {
                    setBusinessInfo(data);
                  }}
                  profileDetails={profileDetails}
                  isProfile
                />
                <ImageUpload
                  {...props}
                  ref={uploadImageInfoRef}
                  onFormSubmit={(data) => {
                    setUploadImageInfo(data);
                  }}
                  profileDetails={profileDetails}
                  isProfile
                />
                <Menu
                  {...props}
                  ref={menuInfoRef}
                  profileDetails={profileDetails}
                  isProfile
                  onFormSubmit={(data) => {
                    setMenuInfo(data);
                  }}
                />
                <OperationHours
                  {...props}
                  ref={operationHourInfoRef}
                  profileDetails={profileDetails}
                  isProfile
                  onFormSubmit={(data) => {
                    setOperationHourInfo(data);
                  }}
                />
                <SocialMedia
                  {...props}
                  ref={socialMediaRef}
                  profileDetails={profileDetails}
                  isProfile
                  onFormSubmit={(data) => {
                    setSocialMedia(data);
                  }}
                />
                <div className="information-row merchantinfosection" id="confirmation">
                  <button
                    type="button"
                    name=""
                    onClick={onSubmit}
                    className="btn primary btn-submit">
                    {t('signUp.otpVerification.submit')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};
export default withLocale(withNotify(withLoader(Profile)));
