import React, { useEffect } from 'react';
import _ from 'lodash';
import { BASE_URL } from '../../common/config';
import * as appImages from '../../assets/images';

const MerchantDetailGallery = ({ platsMerchantDetails }) => {
  useEffect(() => {
    const merchantDetailThumbs = new window.Swiper('.merchantdetail-thumbs', {
      spaceBetween: 0,
      slidesPerView: 'auto',
      freeMode: true,
      watchSlidesProgress: true
    });
    new window.Swiper('.merchantdetail-image', {
      spaceBetween: 0,
      thumbs: {
        swiper: merchantDetailThumbs
      }
    });
  }, []);

  return (
    <div className="merchantdetail-img">
      <div className="d-block d-md-none merchant-detail-mobile-title">
        <div className="row">
          <div className="col">
            <h2>{platsMerchantDetails.businessName}</h2>
          </div>
          <div className="col">
            <div className="media-share">
              <ul>
                <li>
                  <a href={platsMerchantDetails.facebook} target="_blank" rel="noreferrer">
                    <img src={appImages.facebookIco} alt="image" />
                  </a>
                </li>
                <li>
                  <a href={platsMerchantDetails.twitter} target="_blank" rel="noreferrer">
                    <img src={appImages.twitterIco} alt="image" />
                  </a>
                </li>
                <li>
                  <a href={platsMerchantDetails.instagram} target="_blank" rel="noreferrer">
                    <img src={appImages.instagramIco} alt="image" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {!_.isEmpty(platsMerchantDetails) && (
        <div className="swiper merchantdetail-image">
          <div className="swiper-wrapper">
            {platsMerchantDetails.businessPhotos.map((photo, index) => (
              <div className="swiper-slide detail-img" key={index}>
                <img
                  src={`${BASE_URL}${photo.link}`}
                  alt=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `${appImages.gallery}`;
                  }}
                />
              </div>
            ))}
          </div>
          <div className="swiper merchantdetail-thumbs">
            <div className="swiper-wrapper">
              {platsMerchantDetails.businessPhotos.map((photo, index) => (
                <div className="swiper-slide" key={index}>
                  <img
                    src={`${BASE_URL}${photo.link}`}
                    alt=""
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = `${appImages.gallery}`;
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MerchantDetailGallery;
