import { connect } from 'react-redux';
import { citiesSet } from '../actions';

const mapDispatchToProps = (dispatch) => ({
  setCities: (data) => {
    dispatch(citiesSet(data));
  }
});

const mapStateToProps = (state) => ({
  cities: state.cities ? state.cities : []
});

export default connect(mapStateToProps, mapDispatchToProps);
