import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import _ from 'lodash';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { uploadImage } from '../../utils/API';
import { BASE_URL } from '../../common/config';
import { imageUploadType } from '../../common/global';
import { renderField, required } from '../../helpers/form-validations';

const ImageUpload = forwardRef(
  ({ setNotify, setLoader, onFormSubmit, profileDetails, isProfile = false }, ref) => {
    const { t } = useTranslation();
    const [gallery, setGallery] = useState([]);
    const submitBtnRef = useRef();
    const [profileImage, setProfileImage] = useState('');

    const getAllImages = () => {
      const galleryImages = [];
      if (isProfile) {
        const { businessPhotos } = profileDetails.business;
        if (businessPhotos.length > 0) {
          businessPhotos.map((galleryImage) => {
            if (galleryImage.isProfile === 0) {
              galleryImages.push(galleryImage.link);
            } else if (galleryImage.isProfile === 1) {
              setProfileImage(galleryImage.link);
            }
          });
          setGallery(galleryImages);
        }
      }
    };

    useEffect(() => {
      getAllImages();
    }, []);
    const onDeleteImage = (type, form, index) => {
      if (type === imageUploadType.PROFILE) {
        form.mutators.setValue('profile', '');
      } else if (type === imageUploadType.GALLERY) {
        const newImages = [...gallery];
        newImages.splice(index, 1);
        setGallery([...newImages]);
        if (!newImages.length) {
          form.mutators.setValue(`gallery`, '');
        }
      }
    };

    const imageUpload = async (imageData, type) => {
      setLoader(true);
      if (imageData.files && imageData.files[0]) {
        const profileImageRes = await uploadImage(imageData.files[0], type);
        const { status, data, message } = profileImageRes;
        setLoader(false);
        if (status) {
          return data;
        }
        setNotify({ message, type: 'error' });
        return false;
      }
    };

    const onSelectImage = async (event, form, type) => {
      const url = await imageUpload(event, { type });
      if (url) {
        if (type === imageUploadType.PROFILE) {
          form.mutators.setValue('profile', url);
        } else if (type === imageUploadType.GALLERY) {
          form.mutators.setValue(`gallery`, url);
          gallery.push(url);
          setGallery([...gallery]);
        }
      }
    };

    const onSubmit = ({ profile }) => {
      onFormSubmit({ profile, gallery });
    };

    useImperativeHandle(ref, () => ({
      onImageParentSubmit() {
        submitBtnRef.current.click();
      }
    }));

    return (
      <div className="information-row merchantinfosection" id="imageupload">
        <h2 className="section-title">{t('signUp.sideMenu.imageUpload')}</h2>
        <Form
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            }
          }}
          onSubmit={onSubmit}
          initialValues={isProfile ? { profile: profileImage, gallery } : {}}
          render={({ form, handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>{t('signUp.imageUpload.profilePhoto')}</label>
                    {values.profile ? (
                      <div className="uploadimg-card gallery-image-view">
                        <button
                          type="button"
                          className="infoitem-close"
                          onClick={() => onDeleteImage(imageUploadType.PROFILE, form)}
                        />
                        <img
                          className="gallery-image"
                          src={`${BASE_URL}${values.profile}`}
                          alt="profileImage"
                        />
                      </div>
                    ) : (
                      <>
                        <div className="uploadimg-card">
                          <div className="btn secondary">
                            <input
                              type="file"
                              accept="image/*"
                              name="profileFile"
                              onChange={(e) =>
                                onSelectImage(e.target, form, imageUploadType.PROFILE)
                              }
                            />
                            <span>
                              {t('signUp.imageUpload.upload')} {t('signUp.imageUpload.img')}
                            </span>
                          </div>
                        </div>
                        <Field type="hidden" name="profile" validate={required}>
                          {renderField}
                        </Field>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>{t('signUp.imageUpload.gallery')}</label>
                    <div className="gallery-section">
                      {!_.isEmpty(gallery) &&
                        gallery.map((image, index) => (
                          <div className="uploadimg-card gallery-image-view" key={index}>
                            <button
                              type="button"
                              className="infoitem-close"
                              onClick={() => onDeleteImage(imageUploadType.GALLERY, form, index)}
                            />
                            <img
                              className="gallery-image"
                              src={`${BASE_URL}${image}`}
                              alt="galleryImage"
                            />
                          </div>
                        ))}

                      {gallery.length < 10 && (
                        <>
                          <div className="uploadimg-card">
                            <div className="btn secondary">
                              <input
                                accept="image/*"
                                type="file"
                                name="galleryFile"
                                onChange={(e) =>
                                  onSelectImage(e.target, form, imageUploadType.GALLERY)
                                }
                              />
                              <span>
                                {t('signUp.imageUpload.upload')} {t('signUp.imageUpload.img')}
                              </span>
                            </div>
                          </div>
                          <Field type="hidden" name="gallery" validate={required}>
                            {renderField}
                          </Field>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4 visually-hidden">
                  <div className="form-group">
                    <button type="submit" ref={submitBtnRef}>
                      {t('signUp.businessInformation.save')}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
);

export default ImageUpload;
