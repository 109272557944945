import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as appImages from '../../assets/images';
import MenuModel from '../Modal/MenuModel';
import { BASE_URL } from '../../common/config';

const MerchantDetailMenu = ({ platsMerchantDetailsMenu, foodTypes }) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [menus, setMenus] = useState([]);
  useEffect(() => {
    new window.Swiper('.ourmenu-slider', {
      slidesPerView: 1.3,
      freeMode: true,
      spaceBetween: 20,
      navigation: {
        nextEl: '.ourmenu-next',
        prevEl: '.ourmenu-prev'
      },
      mousewheel: false,
      keyboard: true,
      breakpoints: {
        600: {
          slidesPerView: 1.3
        },
        767: {
          slidesPerView: 3.5
        },
        1024: {
          slidesPerView: 3.5
        },
        1281: {
          slidesPerView: 4
        },
        1400: {
          slidesPerView: 5
        }
      }
    });
  }, []);

  useEffect(() => {
    const da = [];
    platsMerchantDetailsMenu = platsMerchantDetailsMenu.map((platsMerchantDetailMenu) => {
      foodTypes.map((foodType) => {
        const o = { ...platsMerchantDetailMenu };
        if (platsMerchantDetailMenu.foodTypeId === foodType.id) {
          o.foodTypeName = foodType.title;
          da.push(o);
        }
      });
    });
    setMenus(da);
  }, []);

  return (
    <div>
      <section className="ourmenu-section">
        <div className="container">
          <div className="ourmenu-block">
            <h2 className="section-title">{t('merchantDetails.ourMenu')}</h2>
            <div className="ourmenu-slider-inner">
              <div className="ourmenu-slider swiper">
                <div className="swiper-wrapper">
                  {!_.isEmpty(menus) ? (
                    menus.map((menu, key) => (
                      <div className="swiper-slide" key={key}>
                        <div
                          onClick={() => setSelectedIndex(key)}
                          data-bs-toggle="modal"
                          data-bs-target="#ourmenumodal"
                          className="ourmenu-card">
                          <div className="ourmenu-card-head">
                            <h4>{menu.title}</h4>
                            <p className="price">RM{menu.price}</p>
                          </div>
                          <div className="ourmenu-img">
                            <img src={`${BASE_URL}${menu.image}`} alt="image" />

                            <a href="javascript:void(0);" className="menu-info">
                              <img src={appImages.menuInfo} alt="icon" />{' '}
                            </a>
                          </div>
                          <div className="ourmenu-btn">
                            <a href="javascript:void(0);" className="btn primary">
                              {menu.foodTypeName}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="justify-content-center">
                      <h5 className="more-plats error-msg">{t('merchants.noMenuErrorMsg')}</h5>
                    </div>
                  )}
                </div>
              </div>
              <div className="swiper-button-next ourmenu-next" />
              <div className="swiper-button-prev ourmenu-prev" />
            </div>
          </div>
        </div>
      </section>
      <MenuModel platsMerchantDetailsMenu={menus} selectedIndex={selectedIndex} />
    </div>
  );
};

export default MerchantDetailMenu;
