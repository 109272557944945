import { connect } from 'react-redux';
import { localeSet } from '../actions';
import * as config from '../../common/config';

const mapDispatchToProps = (dispatch) => ({
  setLocale: (data) => {
    dispatch(localeSet(data));
  }
});

const mapStateToProps = (state) => ({
  locale: state.locale ? state.locale : config.DEFAULT_LOCALE
});

export default connect(mapStateToProps, mapDispatchToProps);
