import { connect } from 'react-redux';
import { foodTypesSet } from '../actions';

const mapDispatchToProps = (dispatch) => ({
  setFoodTypes: (data) => {
    dispatch(foodTypesSet(data));
  }
});

const mapStateToProps = (state) => ({
  foodTypes: state.foodTypes ? state.foodTypes : []
});

export default connect(mapStateToProps, mapDispatchToProps);
