import React from 'react';
import { useTranslation } from 'react-i18next';
import * as appImages from '../../assets/images/index';
import withLocale from '../../redux/Hoc/withLocale';

const AboutPlats = () => {
  const { t } = useTranslation();
  return (
    <section className="container">
      <div className="about-plats">
        <h2 className="section-title">{t('home.aboutPlats')}</h2>
        <div className="about-row row">
          <div className="col-lg-4 about-img">
            <img src={appImages.aboutMe} alt="image5" />
          </div>
          <div className="col-lg-8 about-text">
            <div className="abouttext-card">
              <p>{t('dashboard.aboutPlats1')} </p>
              <p>{t('dashboard.aboutPlats2')}</p>
              <p>{t('dashboard.aboutPlats3')} </p>
              <a className="btn primary">{t('home.readMore')}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withLocale(AboutPlats);
