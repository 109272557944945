import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import * as appImages from '../../assets/images/index';
import { BASE_URL } from '../../common/config';
import withLocale from '../../redux/Hoc/withLocale';

const CustomSliders = ({
  sliders = [],
  onClickSlider,
  locale,
  onClickInstagramBusiness,
  onClickViewAll
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (sliders.length > 0) {
      sliders.map((slider, index) => {
        new window.Swiper(`#home-slider-${index}`, {
          slidesPerView: 2.5,
          freeMode: true,
          spaceBetween: 10,
          navigation: {
            nextEl: `.slider-next-${index}`,
            prevEl: `.slider-prev-${index}`
          },
          mousewheel: false,
          keyboard: true,
          breakpoints: {
            600: {
              slidesPerView: 2.5
            },
            768: {
              slidesPerView: 4
            },
            1024: {
              slidesPerView: 5
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 20
            },
            1400: {
              slidesPerView: 6,
              spaceBetween: 20
            }
          }
        });
      });
    }

    new window.Swiper('.partcommunity-slider', {
      slidesPerView: 1.2,
      freeMode: true,
      spaceBetween: 10,
      navigation: {
        nextEl: '.partcommunity-next',
        prevEl: '.partcommunity-prev'
      },
      mousewheel: false,
      keyboard: true,
      breakpoints: {
        600: {
          slidesPerView: 2.5
        },
        768: {
          slidesPerView: 4
        },
        1024: {
          slidesPerView: 5
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 20
        },
        1400: {
          slidesPerView: 5,
          spaceBetween: 20
        }
      }
    });
  }, [sliders]);

  return (
    <>
      {sliders.map((slider, index) =>
        slider.type !== 'Instagram' ? (
          <section
            className={`top-pick-section ${slider.isPrimary === 1 ? 'hightlight-section' : ''} ${
              slider.data.length < 5 ? 'swiper-wrapper-center' : ''
            }`}
            key={`top-pick-section${index}`}>
            {slider.data.length > 0 && (
              <div className="container">
                <div className="toppick-block">
                  <h2 className="section-title">{slider.title[locale]}</h2>
                  <div className="toppick-slider-inner">
                    <div className="toppick-slider swiper" id={`home-slider-${index}`}>
                      <div className="swiper-wrapper">
                        {slider.data.length > 0 &&
                          slider.data.map((value) => (
                            <div className="swiper-slide" key={`toppick-block-${value.id}`}>
                              <a
                                className="food-card"
                                href="javascript:void(0);"
                                onClick={() => {
                                  onClickSlider(slider.type, value.id, value);
                                }}>
                                <div className="food-img">
                                  {slider.type === 'Restaurant' ? (
                                    <img
                                      src={`${BASE_URL}${value['businessPhotos.image']}`}
                                      alt="image5"
                                      className="image-height-width"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = `${appImages.gallery}`;
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={`${BASE_URL}${value.image}`}
                                      alt="image5"
                                      className="image-height-width"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = `${appImages.gallery}`;
                                      }}
                                    />
                                  )}
                                </div>
                                <div className="food-text">
                                  <h4
                                    title={
                                      slider.type === 'Restaurant'
                                        ? value.businessName
                                        : value.title
                                    }>
                                    {slider.type === 'Restaurant'
                                      ? value.businessName
                                      : value.title}
                                  </h4>
                                </div>
                              </a>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className={`swiper-button-next toppick-next slider-next-${index}`} />
                    <div className={`swiper-button-prev toppick-prev slider-prev-${index}`} />
                  </div>

                  <div className="btn secondary" onClick={() => onClickViewAll(slider)}>
                    {t('home.viewAll')}
                  </div>
                </div>
              </div>
            )}
          </section>
        ) : (
          <section className="partcommunity-section" key={`partcommunity-section${index}`}>
            {slider.data.length > 0 && (
              <div className="container">
                <div className="partcommunity-block">
                  <h2 className="section-title">{slider.title[locale]}</h2>
                  <div className="partcommunity-slider-inner">
                    <div className="partcommunity-slider swiper">
                      <div className="swiper-wrapper">
                        {slider.data.map((instagramValue) => (
                          <div
                            className="swiper-slide"
                            key={`partcommunity-block${instagramValue.id}`}>
                            <div className="community-card">
                              <div className="user-field">
                                <iframe
                                  title={instagramValue.instaCode}
                                  width="100%"
                                  height="300"
                                  src={`https://instagram.com/p/${instagramValue.instaCode}/embed`}
                                  frameBorder="0"
                                />
                              </div>
                              <div className="community-order">
                                <div
                                  className="order-img cursor-pointer"
                                  onClick={() =>
                                    onClickInstagramBusiness(instagramValue.whatsappNumber)
                                  }>
                                  <img
                                    src={`${BASE_URL}${instagramValue['businessPhotos.image']}`}
                                    alt="businessPhoto"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = `${appImages.profile}`;
                                    }}
                                  />
                                </div>
                                <div className="order-text">
                                  <h4
                                    className="cursor-pointer"
                                    onClick={() =>
                                      onClickInstagramBusiness(instagramValue.whatsappNumber)
                                    }>
                                    {instagramValue.businessName}
                                  </h4>
                                  {!_.isEmpty(instagramValue.orderLink) && (
                                    <a
                                      href={instagramValue.orderLink}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="order-btn">
                                      <img src={appImages.callIcon} alt="callIcon" />
                                      {t('home.orderNow')}
                                    </a>
                                  )}
                                  {!_.isEmpty(instagramValue.whatsappNumber) && (
                                    <span>
                                      <a
                                        href={`https://wa.me/60${instagramValue.whatsappNumber}`}
                                        className="whatsapp-btn"
                                        target="_blank"
                                        rel="noreferrer">
                                        {' '}
                                        <img src={appImages.callIcon} alt="icon" />{' '}
                                        {t('merchantDetails.wpOrder')}
                                      </a>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="swiper-button-next partcommunity-next" />
                    <div className="swiper-button-prev partcommunity-prev" />
                  </div>
                </div>
              </div>
            )}
          </section>
        )
      )}
    </>
  );
};

export default withLocale(CustomSliders);
