import React from 'react';
import { trim } from 'lodash';
import i18n from '../i18n';

const FormField = (props) => {
  const { input, meta, onDropDownChange = () => {}, ...rest } = props;

  const dropDownChange = (event) => {
    const {
      input: { name }
    } = props;
    if (onDropDownChange) {
      onDropDownChange({ name, value: event.target.value });
    }
  };

  return (
    <>
      {input.type === 'dropdown' ? (
        <select {...rest} {...input} {...(onDropDownChange ? { onChange: dropDownChange } : {})}>
          {rest.placeholder && <option value="">{rest.placeholder}</option>}
          {rest.data.map((value, key) => (
            <option key={`reduxDropdown${key}`}>{value}</option>
          ))}
        </select>
      ) : null}
      {input.type === 'text' || input.type === 'password' || input.type === 'checkbox' ? (
        <input {...rest} {...input} type={input.type} />
      ) : input.type === 'textarea' ? (
        <textarea {...rest} {...input} />
      ) : null}
      {meta.touched && meta.error && (
        <p className="text-danger text-end error-text">
          &nbsp;
          {meta.error.includes('validation.') ? i18n.t(meta.error) : meta.error}
        </p>
      )}
    </>
  );
};

export const renderField = (props) => <FormField {...props} />;

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

export const required = (value) => (trim(value) ? undefined : 'validation.required');

export const maxLength = (max) => (value) =>
  value && value.length > max
    ? `${i18n.t('validation.mustBe')} ${max} ${i18n.t('validation.orLess')}`
    : undefined;

export const maxValue = (max) => (value) =>
  value && value >= max ? `Must be ${max} or less` : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min ? `validation.mustBe${min}Char` : undefined;

export const number = (value) =>
  value && !/^[0-9\b]+$/.test(value) ? 'validation.mustBeNumber' : undefined;

export const numberReg = /^\d+$/;

export const minValue = (min) => (value) =>
  value && value < min ? `${i18n.t('validation.minValue')} ${min}` : undefined;

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'validation.invalidEmail'
    : undefined;

export const usPhoneNumber = (value) =>
  value && !/^\(\d{3}\)\s?\d{3}-\d{4}$/i.test(value) ? 'Invalid phone' : undefined;

export const passwordsMustMatch = (value, allValues) =>
  value !== allValues.password ? 'Passwords do not match' : undefined;

export const link = (value) =>
  value &&
  !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
    value
  )
    ? 'validation.invalidLink'
    : undefined;

export const latitude = (value) =>
  value && !/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/.test(value)
    ? 'validation.invalidCoordinates'
    : undefined;

export const longitude = (value) =>
  value && !/^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(value)
    ? 'validation.invalidCoordinates'
    : undefined;
