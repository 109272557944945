import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactUsInformation = () => {
  const { t } = useTranslation();
  return (
    <div className="conatct-detail">
      <div className="conatct-map">
        <iframe
          title="contact information"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11268.604521702277!2d101.50690593103204!3d3.080437823613995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc536ce66e075d%3A0xffeb7c15e511a53b!2sDewan%20MBSA%20Seksyen%204!5e0!3m2!1sen!2sin!4v1651075449079!5m2!1sen!2sin"
          width="600"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
      <h2>{t('contactUs.contactInfo')}</h2>
      <ul>
        <li className="mail-ico">
          <a href="mailto:officialplatselangor@gmail.com">officialplatselangor@gmail.com</a>
        </li>
        <li className="phone-ico">
          <a className="phone-ico" href="tel:0192018119">
            0192018119
          </a>
        </li>
        <li className="address-ico">
          Tingkat 23, Bangunan Darul Ehsan , Jalan Indah, Seksyen 14, 40000 Shah Alam, Selangor
        </li>
      </ul>
    </div>
  );
};

export default ContactUsInformation;
