import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { OnChange } from 'react-final-form-listeners';
import { getTimeSlots } from '../../common/global';
import { operationalHours } from '../../helpers/app';
import i18n from '../../i18n';

const OperationHours = forwardRef(({ onFormSubmit, profileDetails, isProfile = false }, ref) => {
  const { t } = useTranslation();
  const timeSlots = getTimeSlots();
  const [hours, setHours] = useState({});
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isOperationalHours, setIsOperationalHours] = useState(true);
  const submitBtnRef = useRef();

  const weekDays = [
    i18n.t('days.sunday'),
    i18n.t('days.monday'),
    i18n.t('days.tuesday'),
    i18n.t('days.wednesday'),
    i18n.t('days.thursday'),
    i18n.t('days.friday'),
    i18n.t('days.saturday')
  ];

  useEffect(() => {
    if (isProfile) {
      setHours(profileDetails.business.workingTime);
    } else if (!isCheckAll) {
      setHours(operationalHours);
    }

    setTimeout(() => {
      window.loadSelectPicker('.from-select');
      window.loadSelectPicker('.to-select');
    }, 500);
  }, [hours]);

  useEffect(() => {
    if (isProfile) {
      setIsOperationalHours(profileDetails.business.isOperationalHours);
    }
  }, []);

  const onSelectOperationalHours = () => {
    setIsOperationalHours(!isOperationalHours);
    setTimeout(() => {
      window.loadSelectPicker('.from-select');
      window.loadSelectPicker('.to-select');
    }, 50);
  };

  const onSubmit = (formData) => {
    Object.keys(hours).map((index) => {
      hours[index].from = formData.from[index];
      hours[index].to = formData.to[index];
    });
    onFormSubmit({ workingTime: JSON.stringify(hours), isOperationalHours });
  };

  useImperativeHandle(ref, () => ({
    onOperationHourParentSubmit() {
      if (isOperationalHours) {
        submitBtnRef.current.click();
      } else {
        onFormSubmit({ workingTime: JSON.stringify(operationalHours), isOperationalHours });
      }
    }
  }));

  const onClickCheckAll = (hoursValues) => {
    setIsCheckAll(true);
    if (hoursValues.hasOwnProperty('isOpen0')) {
      Object.keys(hours).map((index) => {
        hours[index].from = hoursValues.from[0];
        hours[index].to = hoursValues.to[0];
        hours[index].isOpen = hoursValues.isOpen0;
      });
    } else {
      Object.keys(hours).map((index) => {
        hours[index].from = hoursValues.from[0];
        hours[index].to = hoursValues.to[0];
      });
    }

    setHours({ ...hours });
    setTimeout(() => {
      window.$('.from-select').selectpicker('refresh');
      window.$('.to-select').selectpicker('refresh');
    }, 100);
  };

  return (
    <div className="information-row merchantinfosection" id="operationhours">
      <h2 className="section-title">
        {t('signUp.sideMenu.opHours')}
        <input
          className="working-hours-checkbox"
          name="operational_hours"
          type="checkbox"
          checked={isOperationalHours}
          onClick={() => {
            onSelectOperationalHours();
          }}
        />
      </h2>
      {isOperationalHours && (
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              {Object.keys(hours).map((index) => (
                <div className="row" key={index}>
                  <div className="col-md-4">
                    <div className="form-group d-flex align-items-center c-operationHours">
                      <label className="d-inline-block">{weekDays[index]}</label>
                      <div className="form-check form-switch">
                        <Field
                          component="input"
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id={`isOpen${index}`}
                          name={`isOpen${index}`}
                          checked={hours[index].isOpen}
                        />
                        <OnChange name={`isOpen${index}`}>
                          {() => {
                            hours[index].isOpen = !hours[index].isOpen;
                            setHours({ ...hours });
                          }}
                        </OnChange>
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                          {hours[index].isOpen ? t('signUp.open') : t('signUp.close')}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className={`col-md-6 ${hours[index].isOpen ? '' : 'd-none'}`}>
                    <div className="form-group time-group">
                      <Field
                        component="select"
                        name={`from[${index}]`}
                        className="from-select boot-select"
                        initialValue={hours[index].from}>
                        {timeSlots.map((hour, idx) => (
                          <option key={idx}>{hour}</option>
                        ))}
                      </Field>
                      {t('signUp.to')}
                      <Field
                        component="select"
                        name={`to[${index}]`}
                        className="to-select boot-select"
                        initialValue={hours[index].to}>
                        {timeSlots.map((hour, idx) => (
                          <option key={idx}>{hour}</option>
                        ))}
                      </Field>
                    </div>
                  </div>

                  {weekDays[index] === t('days.sunday') && (
                    <span className="btn primary btn-item" onClick={() => onClickCheckAll(values)}>
                      {t('signUp.applyToAll')}
                    </span>
                  )}
                </div>
              ))}
              <div className="col-md-4 visually-hidden">
                <div className="form-group">
                  <button ref={submitBtnRef} type="submit">
                    Save
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      )}
    </div>
  );
});
export default OperationHours;
