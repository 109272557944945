import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import withLocale from '../redux/Hoc/withLocale';
import withPrivacyPolicy from '../redux/Hoc/withPrivacyPolicy';

const PrivacyPolicy = ({ locale, privacyPolicy }) => {
  const { t } = useTranslation();
  const [privacyAndPolicy, setPrivacyAndPolicy] = useState();

  useEffect(() => {
    setPrivacyAndPolicy(privacyPolicy[locale]);
  }, [locale, privacyPolicy]);

  return (
    <div className="main-section">
      <section className="top-pick-section">
        <div className="container">
          <div className="toppick-block terms-and-condition">
            <h2 className="section-title">{t('layout.privacyPolicy')}</h2>
          </div>
          <p dangerouslySetInnerHTML={{ __html: privacyAndPolicy }} />
        </div>
      </section>
    </div>
  );
};

export default withLocale(withPrivacyPolicy(PrivacyPolicy));
