import React, { useEffect, useState } from 'react';
import * as appImages from '../assets/images/index';
import IntroPlats from '../components/AboutUs/IntroPlats';
import WhyUse from '../components/AboutUs/WhyUse';
import HowToUse from '../components/AboutUs/HowToUse';
import FAQs from '../components/AboutUs/FAQs';
import withLoader from '../redux/Hoc/withLoader';
import withNotify from '../redux/Hoc/withNotify';
import withLocale from '../redux/Hoc/withLocale';
import { getAllFaqList } from '../utils/API';

const AboutUs = ({ setLoader, locale }) => {
  const [faqs, setFaqs] = useState([]);

  const getFaqList = async () => {
    const faqRes = await getAllFaqList();
    const { status } = faqRes;
    if (status) {
      setLoader(false);
      setFaqs(faqRes.data);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    getFaqList();
  }, []);
  return (
    <div className="main-section">
      <section className="page-banner">
        <div className="container">
          <div className="page-banner-img">
            <img src={appImages.aboutBanner} alt="About Us" />
          </div>
        </div>
      </section>
      <section className="about-page-section">
        <IntroPlats />
        <WhyUse />
        <HowToUse />
        <FAQs faqs={faqs} locale={locale} />
      </section>
    </div>
  );
};

export default withNotify(withLoader(withLocale(AboutUs)));
