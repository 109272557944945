import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { composeValidators, email, renderField, required } from '../../helpers/form-validations';
import withLoader from '../../redux/Hoc/withLoader';
import withNotify from '../../redux/Hoc/withNotify';
import { contactUs } from '../../utils/API';

const ContactUsForm = ({ setNotify, setLoader }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSelectedTermsCondition, setIsSelectedTermsCondition] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const onSubmit = async (formData, form) => {
    let errMessage = '';
    errMessage = !isSelectedTermsCondition ? 'Required' : '';
    setErrMsg(errMessage);
    if (errMessage === '') {
      setLoader(true);
      const contactUsRes = await contactUs(formData);
      const { status, message } = contactUsRes;
      if (status) {
        setNotify({ message, type: 'success' });
        navigate('/');
        form.reset();
        form.resetFieldState('name');
        form.resetFieldState('email');
        form.resetFieldState('address');
      } else {
        setNotify({ message, type: 'error' });
      }
      setLoader(false);
    }
  };

  const onSelectTermsAndCondition = () => {
    setIsSelectedTermsCondition(!isSelectedTermsCondition);
    setErrMsg('');
  };

  return (
    <div className="conatct-left">
      <h2 className="section-title">{t('contactUs.contactUs')}</h2>
      <div className="conatct-form">
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>{t('contactUs.name')}</label>
                <Field
                  className="form-control"
                  name="name"
                  type="text"
                  validate={composeValidators(required)}>
                  {renderField}
                </Field>
              </div>
              <div className="form-group">
                <label>{t('contactUs.emailAddress')}</label>
                <Field
                  className="form-control"
                  name="email"
                  type="text"
                  validate={composeValidators(required, email)}>
                  {renderField}
                </Field>
              </div>
              <div className="form-group">
                <label>{t('contactUs.address')}</label>
                <Field
                  className="form-control"
                  name="address"
                  type="text"
                  validate={composeValidators(required)}>
                  {renderField}
                </Field>
              </div>
              <div className="custom-checkbox">
                <label>
                  <input
                    className="form-control"
                    name="terms_condition"
                    type="checkbox"
                    onClick={() => onSelectTermsAndCondition()}
                  />

                  <span>
                    {t('signUp.confirmation.accept')}{' '}
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="modal"
                      data-bs-target="#termconditionmodal">
                      {t('layout.t&c')}
                    </a>
                  </span>
                  <div className="error text-danger">{errMsg}</div>
                </label>
              </div>
              <div className="form-btn">
                <input
                  type="submit"
                  name=""
                  value={t('signUp.otpVerification.submit')}
                  className="btn primary"
                />
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default withLoader(withNotify(ContactUsForm));
