import { store } from '../redux/store';
import { notifySet, resetRedux } from '../redux/actions';

export const classNames = (...classes) => classes.filter(Boolean).join(' ');

export const userLogout = async () => {
  setTimeout(() => {
    store.dispatch(resetRedux());
    store.dispatch(notifySet({ type: 'success', message: 'You have successfully signed out.' }));
    window.location = '/';
  }, 100);
};

export const operationalHours = {
  0: { from: '00:00', to: '00:00', isOpen: true },
  1: { from: '00:00', to: '00:00', isOpen: true },
  2: { from: '00:00', to: '00:00', isOpen: true },
  3: { from: '00:00', to: '00:00', isOpen: true },
  4: { from: '00:00', to: '00:00', isOpen: true },
  5: { from: '00:00', to: '00:00', isOpen: true },
  6: { from: '00:00', to: '00:00', isOpen: true }
};

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'capitalize', {
  value() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

export const getAddressObjFromGeocodeResponse = (response) => {
  let address = null;
  let city = null;
  let state = null;
  let posCode = null;
  let country = null;

  // eslint-disable-next-line camelcase
  response.results.map(({ address_components }) => {
    // eslint-disable-next-line camelcase
    address_components.map(({ long_name, types }) => {
      if (!address && types.indexOf('route') > -1) {
        // eslint-disable-next-line camelcase
        address = long_name;
      }
      if (!city && types.indexOf('locality') > -1) {
        // eslint-disable-next-line camelcase
        city = long_name;
      }
      if (!state && types.indexOf('administrative_area_level_1') > -1) {
        // eslint-disable-next-line camelcase
        state = long_name;
      }
      if (!posCode && types.indexOf('postal_code') > -1) {
        // eslint-disable-next-line camelcase
        posCode = long_name;
      }
      if (!country && types.indexOf('country') > -1) {
        // eslint-disable-next-line camelcase
        country = long_name;
      }
    });
  });

  return { address, city, state, posCode, country };
};
