import { connect } from 'react-redux';
import { subCategoriesSet } from '../actions';

const mapDispatchToProps = (dispatch) => ({
  setSubCategories: (data) => {
    dispatch(subCategoriesSet(data));
  }
});

const mapStateToProps = (state) => ({
  subCategories: state.subCategories ? state.subCategories : []
});

export default connect(mapStateToProps, mapDispatchToProps);
