import { connect } from 'react-redux';
import { businessLicenseTypesSet } from '../actions';

const mapDispatchToProps = (dispatch) => ({
  setBusinessLicenseTypes: (data) => {
    dispatch(businessLicenseTypesSet(data));
  }
});

const mapStateToProps = (state) => ({
  businessLicenseTypes: state.businessLicenseTypes ? state.businessLicenseTypes : []
});

export default connect(mapStateToProps, mapDispatchToProps);
