import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { composeValidators, email, renderField, required } from '../helpers/form-validations';
import * as appImages from '../assets/images';

import { forgotPassword } from '../utils/API';
import withLoader from '../redux/Hoc/withLoader';
import withNotify from '../redux/Hoc/withNotify';
import withLocale from '../redux/Hoc/withLocale';

const ForgotPassword = ({ setLoader, setNotify }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onSubmit = async (formData) => {
    setLoader(true);
    const forgotPasswordRes = await forgotPassword(formData);
    const { status, message } = forgotPasswordRes;
    if (status) {
      setLoader(false);
      setNotify({ message, type: 'success' });
      navigate('/auth');
    } else {
      setLoader(false);
      setNotify({ message, type: 'error' });
    }
  };

  return (
    <div className="main-section">
      <section className="login-page">
        <div className="loginpage-img">
          <img src={appImages.loginImg} alt="Plats Platforms" />
        </div>
        <div className="loginpage-right">
          <div className="loginpage-block">
            <h2>{t('logIn.fPass')}</h2>
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="login-form">
                    <div className="form-group">
                      <label>{t('logIn.email')}</label>
                      <Field
                        className="form-control"
                        name="email"
                        type="text"
                        validate={composeValidators(required, email)}>
                        {renderField}
                      </Field>
                    </div>
                    <div className="form-btn">
                      <input
                        type="submit"
                        name=""
                        value={t('forgotPass.getNewPass')}
                        className="btn primary loginbtn"
                      />
                      <Link to="/auth" className="forgotpass-link">
                        {t('forgotPass.logIn')}
                      </Link>
                    </div>
                    <p className="login-text">
                      {t('logIn.account')}{' '}
                      <Link to="/signup" className="forgotpass-link">
                        {t('logIn.signUpHere')}
                      </Link>
                    </p>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default withLoader(withNotify(withLocale(ForgotPassword)));
