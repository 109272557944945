import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import MerchantDetailGallery from '../components/MerchantsDetails/MerchantDetailGallery';
import MerchantDetailInfo from '../components/MerchantsDetails/MerchantDetailInfo';
import MerchantDetailMenu from '../components/MerchantsDetails/MerchantDetailMenu';
import withLocale from '../redux/Hoc/withLocale';
import withLoader from '../redux/Hoc/withLoader';
import withNotify from '../redux/Hoc/withNotify';
import { getAllFoodTypes, getMerchantsDetails } from '../utils/API';

const MerchantsDetails = ({ setLoader, setNotify }) => {
  const [platsMerchantDetails, setPlatsMerchantDetails] = useState(null);
  const [foodTypes, setFoodTypes] = useState([]);
  const { id } = useParams();

  const getMerchantDetails = async () => {
    setLoader(true);
    const merchantDetailsRes = await getMerchantsDetails(id);
    const { data, status, message } = merchantDetailsRes;

    if (status) {
      setLoader(false);
      setPlatsMerchantDetails(data);
    } else {
      setLoader(false);
      setNotify({ message, type: 'error' });
    }
  };

  const getFoodTypes = async () => {
    const foodTypeRes = await getAllFoodTypes();
    setFoodTypes(foodTypeRes.data);
  };

  useEffect(() => {
    getMerchantDetails();
    getFoodTypes();
  }, []);

  return (
    <div className="main-section">
      <section className="merchantdetail-page">
        <div className="merchantdetail-sec1">
          <div className="container">
            {!_.isEmpty(platsMerchantDetails) && (
              <div className="merchant-detail">
                <MerchantDetailGallery platsMerchantDetails={platsMerchantDetails} />
                <MerchantDetailInfo platsMerchantDetails={platsMerchantDetails} />
              </div>
            )}
          </div>
        </div>
      </section>
      {!_.isEmpty(platsMerchantDetails) &&
        platsMerchantDetails.businessMenus.length > 0 &&
        !_.isEmpty(foodTypes) && (
          <MerchantDetailMenu
            platsMerchantDetailsMenu={platsMerchantDetails.businessMenus}
            foodTypes={foodTypes}
          />
        )}
    </div>
  );
};

export default withLocale(withLoader(withNotify(MerchantsDetails)));
