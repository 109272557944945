import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as appImages from '../../assets/images';
import withLocale from '../../redux/Hoc/withLocale';

const SideMenu = ({ isConfirmation = true, locale }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  return (
    <div>
      <h2 className="section-title">{t('signUp.sideMenu.merchantInfo')}</h2>
      <ul className="merchantinfo-list">
        <li>
          <a className="active info-ico" href="#infobusiness">
            <i>
              <img src={appImages.businessInformation} alt="icon" />
            </i>{' '}
            {t('signUp.sideMenu.businessInformation')}
          </a>
        </li>
        {isConfirmation && (
          <li>
            <a href="#confirmation" className="confirm-ico">
              <i>
                <img src={appImages.confirmation} alt="icon" />
              </i>{' '}
              {t('signUp.sideMenu.confirmation')}
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default withLocale(SideMenu);
