import { connect } from 'react-redux';
import { categoriesSet } from '../actions';

const mapDispatchToProps = (dispatch) => ({
  setCategories: (data) => {
    dispatch(categoriesSet(data));
  }
});

const mapStateToProps = (state) => ({
  categories: state.categories ? state.categories : []
});

export default connect(mapStateToProps, mapDispatchToProps);
