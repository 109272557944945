import React from 'react';
import { useTranslation } from 'react-i18next';
import * as appImages from '../../assets/images/index';

const WhyUse = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <h2 className="section-title">{t('aboutUs.whyUse')}?</h2>
      <div className="aboutblock-card">
        <div className="aboutblock-img">
          <img src={appImages.aboutImg2} alt="aboutImg2" />
        </div>
        <h4 className="mb-2">{t('about.whyUsePlats.achievement')}</h4>
        <h3>1</h3>
        <p>{t('about.whyUsePlats.step1')}</p>
        <h3>2</h3>
        <p>{t('about.whyUsePlats.step2')}</p>
        <h3>3</h3>
        <p>{t('about.whyUsePlats.step3')}</p>
      </div>
    </div>
  );
};

export default WhyUse;
