import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { getAllSettings, getAllSliders, getAllVideos } from '../utils/API';
import withLoader from '../redux/Hoc/withLoader';
import withNotify from '../redux/Hoc/withNotify';
import withMerchantFilter from '../redux/Hoc/withMerchantFilter';
import withLocale from '../redux/Hoc/withLocale';
import withIsAdvanceSearch from '../redux/Hoc/withIsAdvanceSearch';
import { DEFAULT_MERCHANT_FILTER } from '../redux/reducers';
import HeroSlider from '../components/Home/HeroSlider';
import CustomSliders from '../components/Home/CustomSliders';
import VideoSlider from '../components/Home/VideoSlider';
import AboutPlats from '../components/Home/AboutPlats';
import PlatsDirectory from '../components/Home/PlatsDirectory';

const Home = ({ setLoader, setMerchantFilter, locale, setIsAdvanceSearchSet }) => {
  const [videos, setVideos] = useState([]);
  const [heroSliders, setHeroSliders] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [allApiStatus, setAllApiStatus] = useState(false);
  const merchantListType = ['BLT', 'Location', 'Categories', 'SubCategories', 'CustomType'];
  const merchantDetailsType = ['Restaurant'];

  const navigate = useNavigate();

  const getAllVideo = async () => {
    const videosRes = await getAllVideos();
    const { status } = videosRes;
    if (status) {
      setVideos(videosRes.data);
    }
  };

  const getAllHeroSlider = async () => {
    const heroSliderRes = await getAllSettings('slider_image');
    const { status } = heroSliderRes;
    if (status) {
      setHeroSliders(heroSliderRes.data);
    }
  };

  const getAllSlider = async () => {
    const sliderRes = await getAllSliders();
    const { status } = sliderRes;
    if (status) {
      setSliders(sliderRes.data);
    }
  };

  const initHome = () => {
    setLoader(true);
    setTimeout(async () => {
      await getAllVideo();
      await getAllHeroSlider();
      await getAllSlider();
      setTimeout(() => {
        setAllApiStatus(true);
        setLoader(false);
      }, 1000);
    }, 1000);
  };

  useEffect(() => {
    initHome();
  }, []);

  const onClickSlider = (selectedSliderType, selectedSliderId, businessDetails) => {
    const merchantType = _.find(merchantListType, (element) => element === selectedSliderType);
    const merchantDetailType = _.find(
      merchantDetailsType,
      (element) => element === selectedSliderType
    );
    if (merchantType !== undefined) {
      if (selectedSliderType === 'BLT') {
        setMerchantFilter({ ...DEFAULT_MERCHANT_FILTER, licenseTypes: [selectedSliderId] });
      } else if (selectedSliderType === 'Location') {
        setMerchantFilter({ ...DEFAULT_MERCHANT_FILTER, city: [selectedSliderId] });
      } else if (selectedSliderType === 'Categories') {
        setMerchantFilter({ ...DEFAULT_MERCHANT_FILTER, categoryTypes: [selectedSliderId] });
      } else if (selectedSliderType === 'SubCategories') {
        setMerchantFilter({ ...DEFAULT_MERCHANT_FILTER, subCategoryTypes: [selectedSliderId] });
      } else if (selectedSliderType === 'CustomType') {
        setMerchantFilter({ ...DEFAULT_MERCHANT_FILTER, sliderCustomTypes: [selectedSliderId] });
      }
      navigate('/merchants');
      setIsAdvanceSearchSet(false);
    } else if (merchantDetailType !== undefined) {
      navigate(
        `/merchants/${
          businessDetails.whatsappNumber || businessDetails.contactInfo || businessDetails.id
        }`
      );
      setIsAdvanceSearchSet(false);
    }
  };

  const onClickViewAll = (selectedSlider) => {
    const selectedSliderIds = [];
    selectedSlider.data.map((slider) => {
      selectedSliderIds.push(slider.id);
    });
    if (selectedSlider.type === 'BLT') {
      setMerchantFilter({ ...DEFAULT_MERCHANT_FILTER, licenseTypes: selectedSliderIds });
    } else if (selectedSlider.type === 'Location') {
      setMerchantFilter({ ...DEFAULT_MERCHANT_FILTER, city: selectedSliderIds });
    } else if (selectedSlider.type === 'Categories') {
      setMerchantFilter({ ...DEFAULT_MERCHANT_FILTER, categoryTypes: selectedSliderIds });
    } else if (selectedSlider.type === 'SubCategories') {
      setMerchantFilter({ ...DEFAULT_MERCHANT_FILTER, subCategoryTypes: selectedSliderIds });
    }
    navigate('/merchants');
    setIsAdvanceSearchSet(false);
  };

  const onClickInstagramBusiness = (whatsappNumber) => {
    navigate(`/merchants/${whatsappNumber}`);
    setIsAdvanceSearchSet(false);
  };

  const onClickThumb = (selectedThumb) => {
    setMerchantFilter({ ...DEFAULT_MERCHANT_FILTER, businessMerchantType: selectedThumb });
    navigate('/merchants');
  };

  return (
    <div>
      {allApiStatus ? (
        <div className="main-section">
          {!_.isEmpty(heroSliders) && (
            <HeroSlider
              heroSliders={heroSliders}
              onClickThumb={(selectedThumb) => onClickThumb(selectedThumb)}
            />
          )}
          {!_.isEmpty(sliders) && (
            <CustomSliders
              locale={locale}
              sliders={sliders}
              onClickViewAll={(selectedSlider) => onClickViewAll(selectedSlider)}
              onClickSlider={(selectedSliderType, selectedSliderId, whatsappNumber) =>
                onClickSlider(selectedSliderType, selectedSliderId, whatsappNumber)
              }
              onClickInstagramBusiness={(whatsappNumber) =>
                onClickInstagramBusiness(whatsappNumber)
              }
            />
          )}
          {!_.isEmpty(videos) && <VideoSlider videos={videos} locale={locale} />}
          <AboutPlats />
          <PlatsDirectory />
        </div>
      ) : (
        <div className="full-screen-empty" />
      )}
    </div>
  );
};
export default withLoader(withNotify(withMerchantFilter(withLocale(withIsAdvanceSearch(Home)))));
