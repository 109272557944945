import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ReactPlayer from 'react-player';
import * as appImages from '../../assets/images/index';

const VideoSlider = ({ videos, locale }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState(videos[0]);
  const [isVideoPlay, setIsVideoPlay] = useState(false);

  useEffect(() => {
    new window.Swiper('.video-thumb', {
      direction: 'horizontal',
      slidesPerView: 'auto',
      freeMode: true,
      watchSlidesProgress: true,
      scrollbar: {
        el: '.swiper-scrollbar'
      },
      mousewheel: true,
      breakpoints: {
        766: {
          direction: 'vertical'
        }
      }
    });
  }, [activeIndex, isVideoPlay]);

  const onCLickVideo = (video, index) => {
    setSelectedVideo(video);
    setActiveIndex(index);
  };

  return (
    <section className="container">
      <div className="video-section">
        <div className="video-big">
          {!_.isEmpty(selectedVideo) && (
            <div className={`video-big-item ${!isVideoPlay ? '' : 'video-big-item-play'}`}>
              <div className="videoiframe">
                <ReactPlayer
                  className="ratio ratio-16x9"
                  playing={isVideoPlay}
                  height="auto"
                  url={selectedVideo.link}
                  controls
                  onPlay={() => {
                    setIsVideoPlay(true);
                  }}
                  onPause={() => {
                    setIsVideoPlay(false);
                  }}
                  width="100%"
                />
              </div>
              {!isVideoPlay && (
                <div className="video-text">
                  <h4>{selectedVideo.title[locale]}</h4>
                  <p>{selectedVideo.desc[locale]} </p>
                </div>
              )}
              {!isVideoPlay && (
                <a
                  className="video-link"
                  onClick={() => {
                    setIsVideoPlay(true);
                  }}>
                  <img src={appImages.playIcon} alt="image4" />
                </a>
              )}
            </div>
          )}
        </div>
        <div className="video-thumb swiper mySwiper">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              {!_.isEmpty(videos) &&
                videos.map((video, index) => (
                  <div
                    className={`video-item ${index === activeIndex ? 'active' : ''}`}
                    key={index}
                    onClick={() => onCLickVideo(video, index)}>
                    <ReactPlayer
                      className="ratio ratio-16x9"
                      playing={false}
                      height="auto"
                      url={video.link}
                      width="100%"
                      style={{ pointerEvents: 'none' }}
                      controls={false}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className="swiper-scrollbar" />
        </div>
      </div>
    </section>
  );
};

export default VideoSlider;
