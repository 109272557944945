import React from 'react';
import { useTranslation } from 'react-i18next';
import * as appImages from '../../assets/images/index';

const HowToUse = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <h2 className="section-title">{t('aboutUs.howToUse')}?</h2>
      <div className="aboutblock-card">
        <div className="aboutblock-video">
          <img src={appImages.aboutImg2} alt="aboutImg2" />
          <a href="javascript:void(0);" className="video-link">
            <img src={appImages.playIcon} alt="playIcon" />
          </a>
        </div>
        <h4 className="mb-2">{t('about.howToUse.roadMap')}</h4>
        <h3>1</h3>
        <p>{t('about.howToUse.step1')}</p>
        <h3>2</h3>
        <p>{t('about.howToUse.step2')}</p>
        <h3>3</h3>
        <p>{t('about.howToUse.step3')}</p>
        <h3>4</h3>
        <p>{t('about.howToUse.step4')}</p>
        <h4 className="mb-2 mt-2">{t('about.howToUse.mission')}</h4>
        <h3>1</h3>
        <p>{t('about.howToUse.missionStep2')}</p>
        <h3>2</h3>
        <p>{t('about.howToUse.missionStep3')}</p>
        <h3>3</h3>
        <p>{t('about.howToUse.missionStep4')}</p>
        <h3>4</h3>
        <p>
          {t('about.howToUse.missionStep1')}
          <div className="p-4">
            <ol type="a">
              <li>{t('about.howToUse.missionStep4Data.a')}</li>
              <li>{t('about.howToUse.missionStep4Data.b')}</li>
              <li>{t('about.howToUse.missionStep4Data.c')}</li>
            </ol>
          </div>
        </p>
      </div>
    </div>
  );
};

export default HowToUse;
