import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../../common/config';

const MenuModel = ({ platsMerchantDetailsMenu, selectedIndex }) => {
  const { t } = useTranslation();
  const [menuSwiper, setMenuSwiper] = useState(null);
  useEffect(() => {
    setMenuSwiper(
      new window.Swiper('.ourmenumodal-slider', {
        slidesPerView: 1,
        navigation: {
          nextEl: '.ourmenumodal-next',
          prevEl: '.ourmenumodal-prev'
        },
        mousewheel: false,
        keyboard: true
      })
    );
  }, []);

  useEffect(() => {
    if (menuSwiper) {
      menuSwiper.slideTo(selectedIndex, 3000, false);
    }
  }, [selectedIndex, menuSwiper]);

  return (
    <div
      className="modal fade ourmenu-modal"
      id="ourmenumodal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          <div className="modal-body">
            <h2 className="section-title">{t('merchantDetails.ourMenu')}</h2>
            <div className="ourmenumodal-sliderinner our-menu-modal">
              <div className="ourmenumodal-slider swiper">
                <div className="swiper-wrapper">
                  {platsMerchantDetailsMenu.map((menu, index) => (
                    <div className="swiper-slide" key={index}>
                      <div className="ourmenu-card">
                        <div className="ourmenu-card-head">
                          <h4>{menu.title}</h4>
                          <p className="price">RM{menu.price}</p>
                        </div>
                        <div className="ourmenu-img">
                          <img src={`${BASE_URL}${menu.image}`} alt="image" />
                        </div>
                        <div className="ourmenu-btn">
                          <a href="javascript:void(0);" className="btn primary">
                            {menu.foodTypeName}
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="swiper-button-next ourmenumodal-next" />
              <div className="swiper-button-prev ourmenumodal-prev" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuModel;
