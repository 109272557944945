import { connect } from 'react-redux';
import { merchantFilterSet } from '../actions';
import { DEFAULT_MERCHANT_FILTER } from '../reducers';

const mapDispatchToProps = (dispatch) => ({
  setMerchantFilter: (data) => {
    dispatch(merchantFilterSet(data));
  }
});

const mapStateToProps = (state) => ({
  merchantFilter: state.merchantFilter ? state.merchantFilter : DEFAULT_MERCHANT_FILTER
});

export default connect(mapStateToProps, mapDispatchToProps);
