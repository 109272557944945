window.loadSelectPicker = function (selector, options = {}) {
  window.$(selector).selectpicker(options);
};

window.loadStickyPart = function () {
  if (window.$(window).width() > 767) {
    window.$('.merchantinfo-left').stick_in_parent({
      offset_top: 200
    });
  }
};
window.closeSideBar = function () {
  if (window.$(window).width() <= 1275) {
    $('.navbar-toggler').click();
  }
};
let verifyModel = null;
window.openVerifyOTPModel = () => {
  verifyModel = new window.bootstrap.Modal(window.$('#verifyOtp'), {});
  verifyModel.show();
};
window.closeVerifyOTPModel = () => {
  verifyModel.hide();
};

window.loadScrollClick = function () {
  window
    .$(window)
    .scroll(function () {
      const scrollDistance = window.$(window).scrollTop() + 200;

      window.$('.merchantinfosection').each(function (i) {
        if (window.$(this).position().top <= scrollDistance) {
          window.$('.merchantinfo-list a.active').removeClass('active');
          window.$('.merchantinfo-list a').eq(i).addClass('active');
        }
      });
    })
    .scroll();

  /* window.$(document).ready(function () {
    window.$('.merchantinfo-list a[href*=#]').bind('click', function (e) {
      e.preventDefault(); // prevent hard jump, the default behavior

      const target = window.$(this).attr('href'); // Set the target as variable

      // perform animated scrolling by getting top-position of target-element and set it as scroll target
      window
        .$('html, body')
        .stop()
        .animate(
          {
            scrollTop: window.$(target).offset().top
          },
          600,
          function () {
            location.hash = target; // attach the hash (#jumptarget) to the pageurl
          }
        );

      return false;
    });
  }); */
};
