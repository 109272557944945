import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Zendesk from 'react-zendesk';
import _ from 'lodash';
import * as appImages from './assets/images';
import { userLogout } from './common/global';
import {
  getAllBusinessLicenseType,
  getAllCategories,
  getAllCities,
  getAllCustomTypes,
  getAllFoodTypes,
  getAllSettings,
  getAllSubCategories
} from './utils/API';
import withLocale from './redux/Hoc/withLocale';
import { BASE_URL, ZENDESK_KEY } from './common/config';
import withTermsAndCondition from './redux/Hoc/withTermsAndCondition';
import withMerchantFilter from './redux/Hoc/withMerchantFilter';
import withUser from './redux/Hoc/withUser';
import withIsAdvanceSearch from './redux/Hoc/withIsAdvanceSearch';
import HeaderFilter from './components/HeaderFilter';
import { DEFAULT_MERCHANT_FILTER } from './redux/reducers';
import withCities from './redux/Hoc/withCities';
import withBusinessLicenseTypes from './redux/Hoc/withBusinessLicenseTypes';
import withFoodTypes from './redux/Hoc/withFoodTypes';
import withSubCategories from './redux/Hoc/withSubCategories';
import withCategories from './redux/Hoc/withCategories';
import withCustomTypes from './redux/Hoc/withCustomTypes';
import withPrivacyPolicy from './redux/Hoc/withPrivacyPolicy';
import { initAnalytics } from './utils/analytics';

const ignoreRoutes = [];

const Layout = ({
  user,
  locale,
  setLocale,
  merchantFilter,
  setMerchantFilter,
  setTermsAndCondition,
  isAdvanceSearch,
  setIsAdvanceSearchSet,
  setCities,
  cities,
  setBusinessLicenseTypes,
  businessLicenseTypes,
  setFoodTypes,
  foodTypes,
  setSubCategories,
  subCategories,
  setCategories,
  categories,
  setCustomTypes,
  customTypes,
  setPrivacyPolicy
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState(
    merchantFilter.hasOwnProperty('text') ? merchantFilter.text : ''
  );
  const [currentRoute, setCurrentRoute] = useState('/');
  const [mbiInitiative, setMbiInitiative] = useState(null);
  const [allApiStatus, setAllApiStatus] = useState(0);
  const [advanceSearchData, setAdvanceSearchData] = useState(DEFAULT_MERCHANT_FILTER);
  const [localeLan, setLocaleLan] = useState(locale);

  const getCities = async () => {
    const citiesRes = await getAllCities();
    setCities(citiesRes.data);
  };

  const getBusinessLicenseType = async () => {
    const businessLicenseTypeRes = await getAllBusinessLicenseType();
    setBusinessLicenseTypes(businessLicenseTypeRes.data);
  };

  const getFoodTypes = async () => {
    const foodTypeRes = await getAllFoodTypes();
    setFoodTypes(foodTypeRes.data);
  };

  const getSubCategories = async (type) => {
    const subCategoriesRes = await getAllSubCategories({ type });
    setSubCategories(subCategoriesRes.data);
  };

  const getCategories = async (type) => {
    const categoriesRes = await getAllCategories(type);
    setCategories(categoriesRes.data);
  };

  const getCustomTypes = async () => {
    const customTypesRes = await getAllCustomTypes();
    setCustomTypes(customTypesRes.data);
  };

  const merchantAll = async () => {
    await getCities();
    await getBusinessLicenseType();
    await getFoodTypes();
    await getSubCategories('food');
    await getCategories('food');
    await getCustomTypes();
    setAllApiStatus(1);
  };

  useEffect(() => {
    merchantAll();
    initAnalytics();
  }, []);

  const onSelectFilterValues = (selectedFilterValues, type) => {
    const oldMerchantFilterData = advanceSearchData;

    if (type === 'city') {
      setAdvanceSearchData({
        ...oldMerchantFilterData,
        city: _.isEmpty(selectedFilterValues)
          ? selectedFilterValues
          : _.uniq([].concat(...oldMerchantFilterData.city, selectedFilterValues))
      });
    } else if (type === 'foodType') {
      setAdvanceSearchData({
        ...oldMerchantFilterData,
        foodType: _.isEmpty(selectedFilterValues) ? selectedFilterValues : selectedFilterValues
      });
    } else if (type === 'categoryTypes') {
      setAdvanceSearchData({
        ...oldMerchantFilterData,
        categoryTypes: _.isEmpty(selectedFilterValues)
          ? selectedFilterValues
          : _.uniq([].concat(...oldMerchantFilterData.categoryTypes, selectedFilterValues))
      });
    } else if (type === 'subCategoryTypes') {
      setAdvanceSearchData({
        ...oldMerchantFilterData,
        subCategoryTypes: _.isEmpty(selectedFilterValues)
          ? selectedFilterValues
          : _.uniq([].concat(...oldMerchantFilterData.subCategoryTypes, selectedFilterValues))
      });
    } else if (type === 'licenseTypes') {
      setAdvanceSearchData({
        ...oldMerchantFilterData,
        licenseTypes: _.isEmpty(selectedFilterValues)
          ? selectedFilterValues
          : _.uniq([].concat(...oldMerchantFilterData.licenseTypes, selectedFilterValues))
      });
    } else if (type === 'sliderCustomTypes') {
      setAdvanceSearchData({
        ...oldMerchantFilterData,
        sliderCustomTypes: _.isEmpty(selectedFilterValues)
          ? selectedFilterValues
          : _.uniq([].concat(...oldMerchantFilterData.sliderCustomTypes, selectedFilterValues))
      });
    }
  };

  const onClickAdvanceSearch = () => {
    if (!_.isEmpty(searchParams)) {
      setMerchantFilter({ advanceSearchData, text: searchParams });
    } else {
      setMerchantFilter(advanceSearchData);
    }
    if (currentRoute !== '/merchants') {
      navigate('/merchants');
    }
    setIsAdvanceSearchSet(false);
  };

  const onClickIsAdvanceSearch = () => {
    setAdvanceSearchData(DEFAULT_MERCHANT_FILTER);
    setIsAdvanceSearchSet(!isAdvanceSearch);
  };

  const getAllTermsConditions = async () => {
    const termsConditionRes = await getAllSettings('terms_conditions');
    const { status, data } = termsConditionRes;
    if (status) {
      setTermsAndCondition(data.value);
    }
  };

  const getAllSetPrivacyPolicy = async () => {
    const privacyPolicyRes = await getAllSettings('privacy_policy');
    const { status, data } = privacyPolicyRes;
    if (status) {
      setPrivacyPolicy(data.value);
    }
  };

  const getMbiList = async () => {
    const mbiListRes = await getAllSettings('mbi_initiative');
    const {
      status,
      data: { value }
    } = mbiListRes;
    if (status) {
      setMbiInitiative(value);
    }
  };

  useEffect(() => {
    getAllTermsConditions();
    getMbiList();
    getAllSetPrivacyPolicy();
  }, [locale]);

  if (ignoreRoutes.indexOf(location.pathname) > -1) {
    return <Outlet />;
  }

  const onLogout = async () => {
    await userLogout();
  };

  useEffect(() => {
    if (currentRoute !== '/merchants') {
      setSearchParams('');
    }

    setCurrentRoute(window.location.pathname + window.location.hash);
  }, [location]);

  useEffect(() => {
    setSearchParams(merchantFilter.text);
  }, [merchantFilter]);

  const onSearch = (searchValue) => {
    setSearchParams(searchValue);
  };

  const onSearchKeyPress = (keyName) => {
    const oldMerchantFilterData = merchantFilter;
    if (keyName === 'Enter') {
      if (location.pathname === '/merchants') {
        setMerchantFilter({ ...oldMerchantFilterData, text: searchParams });
      } else {
        setMerchantFilter({});
        setMerchantFilter({ ...oldMerchantFilterData, text: searchParams });
        navigate('/merchants');
        setIsAdvanceSearchSet(false);
      }
    }
  };

  const onClickLogo = () => {
    setSearchParams('');
    setMerchantFilter({});
    navigate('/');
  };

  useEffect(() => {
    setLocaleLan(localeLan);
  }, [localeLan]);

  const onClickLan = () => {
    setLocaleLan(localeLan === 'en' ? 'bm' : 'en');
    setLocale(locale === 'en' ? 'bm' : 'en');
  };

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="header-wraper">
            <div className="header-logo">
              <a href="" onClick={() => onClickLogo()}>
                <img src={appImages.logo} alt="Plats Platforms" />
              </a>
            </div>
            <div className="header-right">
              <div className="header-nav justify-content-end d-flex align-content-end">
                <button
                  className="navbar-toggler border-0"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#headnavbar"
                  aria-controls="headnavbarlabel">
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="head-nav d-flex"
                  tabIndex="-1"
                  id="headnavbar"
                  aria-labelledby="headnavbarlabel">
                  <ul className="menu">
                    <li>
                      <Link
                        to="/"
                        className={currentRoute === '/' ? 'active' : ''}
                        onClick={() => {
                          window.closeSideBar();
                        }}>
                        {t('layout.home')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about-us"
                        className={currentRoute === '/about-us' ? 'active' : ''}
                        onClick={() => {
                          window.closeSideBar();
                        }}>
                        {t('layout.aboutPlats')}
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        onClick={() => {
                          window.closeSideBar();
                        }}
                        to="/resources"
                        className={currentRoute === '/resources' ? 'active' : ''}>
                        {t('layout.resources')}
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to="/about-us#FAQs"
                        className={currentRoute === '/about-us#FAQs' ? 'active' : ''}
                        onClick={() => {
                          window.closeSideBar();
                          setTimeout(() => {
                            document.getElementById('FAQs').scrollIntoView();
                          }, 400);
                        }}>
                        {t('layout.faq')}
                      </Link>
                    </li>
                    {!_.isEmpty(mbiInitiative) && (
                      <li>
                        <Link
                          onClick={() => {
                            window.closeSideBar();
                          }}
                          to=""
                          className={currentRoute === '/mbi-initiative' ? 'active' : ''}>
                          {mbiInitiative.title[locale]}
                        </Link>{' '}
                        <span className="nav-arrow" />
                        <ul className="sub-menu">
                          {mbiInitiative[locale].map((initiative) => (
                            <li>
                              <a href={initiative.link} target="_blank" rel="noreferrer">
                                {initiative.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )}

                    <li>
                      <Link
                        onClick={() => {
                          window.closeSideBar();
                        }}
                        to="/terms-conditions"
                        className={currentRoute === '/terms-conditions' ? 'active' : ''}>
                        {t('layout.t&c')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          window.closeSideBar();
                        }}
                        to="/contact-us"
                        className={currentRoute === '/contact-us' ? 'active' : ''}>
                        {t('contactUs.contactUs')}
                      </Link>
                    </li>
                    <li className="d-block d-md-none">
                      <Link
                        onClick={() => {
                          window.closeSideBar();
                        }}
                        to="/signup"
                        className={currentRoute === '/signup' ? 'active' : ''}>
                        {t('layout.signUp')}
                      </Link>
                    </li>
                    <li className="d-block d-md-none">
                      <Link
                        onClick={() => {
                          window.closeSideBar();
                        }}
                        to="/auth"
                        className={currentRoute === '/auth' ? 'active' : ''}>
                        {t('layout.logIn')}
                      </Link>
                    </li>
                  </ul>
                  <label className="language-switch d-none d-xl-block">
                    <input
                      type="checkbox"
                      id="togBtn"
                      onClick={() => onClickLan()}
                      checked={localeLan === 'bm'}
                    />
                    <div className="slider round">
                      <span className="on">BM</span>
                      <span className="off">EN</span>
                    </div>
                  </label>
                </div>
              </div>
              <div className="header-block">
                <div className="header-search">
                  <div>
                    <input
                      type="text"
                      name=""
                      placeholder={t('layout.search')}
                      className="search-input"
                      onKeyPress={(event) => onSearchKeyPress(event.key)}
                      onChange={(event) => onSearch(event.target.value)}
                      value={searchParams}
                    />
                  </div>
                </div>
                <a
                  className="pointer advance-search btn primary text-nowrap w-auto"
                  onClick={() => {
                    onClickIsAdvanceSearch();
                  }}>
                  {t('layout.advanceSearch')}
                </a>
                {user.id !== null ? (
                  <>
                    <img
                      role="presentation"
                      className="profile"
                      src={`${BASE_URL}${user.profile}`}
                      alt="no-profile"
                      onClick={() => navigate('/profile')}
                    />
                    <button type="button" className="btn primary" onClick={() => onLogout()}>
                      {t('layout.logout')}
                    </button>
                  </>
                ) : (
                  <>
                    <Link to="/signup" className="btn primary btn-signup">
                      {t('layout.signUp')}
                    </Link>
                    <Link to="/auth" className="btn secondary btn-login">
                      {t('layout.logIn')}
                    </Link>
                  </>
                )}
              </div>
              <a
                className="btn btn-search"
                onClick={() => {
                  onClickIsAdvanceSearch();
                }}>
                <img src={appImages.searchIco} alt="Search" />
              </a>
              <label className="language-switch d-block d-xl-none">
                <input
                  type="checkbox"
                  id="togBtn"
                  onClick={() => onClickLan()}
                  checked={localeLan === 'bm'}
                />
                <div className="slider round">
                  <span className="on">BM</span>
                  <span className="off">EN</span>
                </div>
              </label>
            </div>
            {allApiStatus === 1 && isAdvanceSearch && (
              <div className="advance-search">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    onClickIsAdvanceSearch();
                  }}
                />
                <input
                  type="text"
                  name=""
                  placeholder={t('layout.search')}
                  className="search-input"
                  onKeyPress={(event) => onSearchKeyPress(event.key)}
                  onChange={(event) => onSearch(event.target.value)}
                  value={searchParams}
                />
                <HeaderFilter
                  advanceSearchData={advanceSearchData}
                  location={cities}
                  businessLicenseTypes={businessLicenseTypes}
                  foodTypes={foodTypes}
                  subCategories={subCategories}
                  categories={categories}
                  onSelectFilterValues={(selectedFilterValues, type) =>
                    onSelectFilterValues(selectedFilterValues, type)
                  }
                  customTypes={customTypes}
                  isHome
                />
                <a onClick={() => onClickAdvanceSearch()} className="btn primary">
                  {t('layout.searchText')}
                </a>
              </div>
            )}
          </div>
        </div>
      </header>
      <Outlet />
      <footer className="footer">
        <div className="container">
          <div className="footer-top">
            <div className="footer-logo">
              <Link to="/">
                <img src={appImages.logo} alt="Plats Platform" />
              </Link>
            </div>
            <ul className="social-link">
              <li>
                <a
                  href="https://www.facebook.com/officialplatselangor"
                  target="_blank"
                  rel="noreferrer">
                  <img src={appImages.facebookIco} alt="Facebook" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/platselangor/" target="_blank" rel="noreferrer">
                  <img src={appImages.twitterIco} alt="Twitter" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/platselangor/" target="_blank" rel="noreferrer">
                  <img src={appImages.instagramIco} alt="Instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCFN4-HIhtPq6dmtcfNy-MFQ"
                  target="_blank"
                  rel="noreferrer">
                  <img src={appImages.youtube} alt="Youtube" />
                </a>
              </li>
              <li>
                <a href="https://www.tiktok.com/@platselangor" target="_blank" rel="noreferrer">
                  <img src={appImages.tiktok} alt="tiktok" />
                </a>
              </li>
            </ul>
            <p>
              {t('layout.copyRight')} | <Link to="/terms-conditions">{t('layout.t&c')}</Link> |{' '}
              {t('layout.allRights')}
            </p>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <p onClick={() => navigate('/terms-conditions')}>{t('layout.t&c')}</p>
          </div>
        </div>
      </footer>
      <Zendesk defer zendeskKey={ZENDESK_KEY} />
    </>
  );
};
export default withBusinessLicenseTypes(
  withCities(
    withSubCategories(
      withFoodTypes(
        withCategories(
          withLocale(
            withCustomTypes(
              withUser(
                withMerchantFilter(
                  withTermsAndCondition(withIsAdvanceSearch(withPrivacyPolicy(Layout)))
                )
              )
            )
          )
        )
      )
    )
  )
);
