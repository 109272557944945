import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import withLocale from '../../redux/Hoc/withLocale';
import { composeValidators, email, renderField, required } from '../../helpers/form-validations';
import { subscribe } from '../../utils/API';
import withNotify from '../../redux/Hoc/withNotify';
import withLoader from '../../redux/Hoc/withLoader';

const PlatsDirectory = ({ setLoader, setNotify }) => {
  const { t } = useTranslation();

  const onSubmit = async (formData, form) => {
    setLoader(true);
    const subscribeRes = await subscribe(formData);
    const { status, message } = subscribeRes;
    if (status) {
      setLoader(false);
      form.reset();
      form.resetFieldState('email');
      setNotify({ message, type: 'success' });
    } else {
      setLoader(false);
      setNotify({ message, type: 'error' });
    }
  };

  return (
    <section className="container">
      <div className="theplat-section">
        {/* <h2 className="section-title">{t('home.thePlatsDirectory')}</h2>
        <div className="theplat-block row">
          <div className="col-lg-3">
            <a className="theplat-item">
              <img src={appImages.findFood} alt="image4" />
              <h4>{t('home.findYourFavouriteFood')}</h4>
            </a>
          </div>
          <div className="col-lg-3">
            <a className="theplat-item">
              <img src={appImages.otherBusiness} alt="image3" />
              <h4>{t('home.otherBusinesses')}</h4>
            </a>
          </div>
          <div className="col-lg-3">
            <a className="theplat-item">
              <img src={appImages.contactSeller} alt="image2" />
              <h4>{t('home.contactYourSeller')}</h4>
            </a>
          </div>
          <div className="col-lg-3">
            <a className="theplat-item">
              <img src={appImages.otherInitiative} alt="image1" />
              <h4>{t('home.otherInitiatives')}</h4>
            </a>
          </div>
        </div> */}
        <div className="more-plats">
          <h3>{t('home.knowMore')}</h3>
          <p>{t('home.newsletter')}</p>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="plat-subscrib">
                  <Field
                    placeholder={t('signUp.businessInformation.emailAdd')}
                    className="plat-input"
                    name="email"
                    type="text"
                    validate={composeValidators(required, email)}>
                    {renderField}
                  </Field>
                  <button type="submit" className="btn primary" name="">
                    {t('home.subscribe')}
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </section>
  );
};

export default withLocale(withNotify(withLoader(PlatsDirectory)));
