import React from 'react';
import { Spinner } from 'react-activity';

const Loader = () => (
  <div className="loading">
    <Spinner size={50} color="#8B1315" />
  </div>
);

export default Loader;
