import React from 'react';
import { useTranslation } from 'react-i18next';

const FAQs = ({ faqs, locale }) => {
  const { t } = useTranslation();
  return (
    <div className="container" id="FAQs">
      <h2 className="section-title">{t('aboutUs.faqs')}</h2>
      <div className="aboutblock-card">
        <div className="accordion aboutblock-accordian" id="accordionExample">
          {faqs.map((faq, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header" id={`heading${faq.id}`}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${faq.id}`}
                  aria-expanded="true"
                  aria-controls={`#collapse${faq.id}`}>
                  {`${t('aboutUs.que')} ${index + 1}`}
                  <span>{faq.question[locale]}</span>
                </button>
              </h2>
              <div
                id={`collapse${faq.id}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading${faq.id}`}
                data-bs-parent="#accordionExample">
                <div className="accordion-body">
                  <p>{faq.answer[locale]}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
