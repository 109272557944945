import { combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import reduxStorage from 'redux-persist/lib/storage';
import {
  isAdvanceSearch,
  loader,
  locale,
  merchantFilter,
  notify,
  termsAndCondition,
  user,
  cities,
  businessLicenseTypes,
  foodTypes,
  subCategories,
  categories,
  customTypes,
  privacyPolicy
} from './reducers';

const persistConfig = {
  key: 'root',
  storage: reduxStorage,
  whitelist: [
    'user',
    'locale',
    'merchantFilter',
    'cities',
    'businessLicenseTypes',
    'foodTypes',
    'subCategories',
    'categories',
    'customTypes'
  ]
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    loader,
    notify,
    user,
    locale,
    merchantFilter,
    termsAndCondition,
    isAdvanceSearch,
    cities,
    businessLicenseTypes,
    foodTypes,
    subCategories,
    categories,
    customTypes,
    privacyPolicy
  })
);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
