import ReactGA4 from 'react-ga4';
import { ENV, GA4_MEASUREMENT_ID } from '../common/config';

export const initAnalytics = () => {
  if (ENV === 'production') {
    ReactGA4.initialize([
      {
        trackingId: GA4_MEASUREMENT_ID
      }
    ]);
  }
};

export const eventAnalytics = (args) => ReactGA4.event(args);
