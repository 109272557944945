import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { composeValidators, minLength, renderField, required } from '../helpers/form-validations';
import * as appImages from '../assets/images';
import { resetPassword } from '../utils/API';
import withLoader from '../redux/Hoc/withLoader';
import withNotify from '../redux/Hoc/withNotify';
import withLocale from '../redux/Hoc/withLocale';

const ResetPassword = ({ setLoader, setNotify }) => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();
  const onSubmit = async (formData) => {
    setLoader(true);
    formData.code = token;
    const resetPassRes = await resetPassword(formData);
    const { status, message } = resetPassRes;
    if (status) {
      setLoader(false);
      setNotify({ message, type: 'success' });
      navigate('/auth');
    } else {
      setLoader(false);
      setNotify({ message, type: 'error' });
    }
  };

  return (
    <div className="main-section">
      <section className="login-page">
        <div className="loginpage-img">
          <img src={appImages.loginImg} alt="Plats Platforms" />
        </div>
        <div className="loginpage-right">
          <div className="loginpage-block">
            <h2>{t('resetPass.resetPass')}</h2>
            <Form
              onSubmit={onSubmit}
              validate={(values) => {
                const errors = {};
                if (values.confirmPassword !== values.password) {
                  errors.confirmPassword = `${t('signUp.businessInformation.mustWatch')}`;
                }
                return errors;
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="login-form">
                    <div className="form-group">
                      <label>{t('resetPass.newPass')}</label>
                      <Field
                        className="form-control"
                        name="password"
                        type="password"
                        validate={composeValidators(required, minLength(6))}>
                        {renderField}
                      </Field>
                    </div>
                    <div className="form-group">
                      <label>{t('resetPass.oldPass')}</label>
                      <Field
                        className="form-control"
                        name="confirmPassword"
                        type="password"
                        validate={composeValidators(required, minLength(6))}>
                        {renderField}
                      </Field>
                    </div>
                    <div className="form-btn">
                      <input
                        type="submit"
                        name=""
                        value={t('resetPass.resetPass')}
                        className="btn primary loginbtn"
                      />
                      <Link to="/auth" className="forgotpass-link">
                        {t('forgotPass.logIn')}
                      </Link>
                    </div>
                    <p className="login-text">
                      {t('logIn.account')}{' '}
                      <Link to="/signup" className="forgotpass-link">
                        {t('logIn.signUpHere')}
                      </Link>
                    </p>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default withNotify(withLoader(withLocale(ResetPassword)));
