import React from 'react';
import ContactUsForm from '../components/ContactUs/ContactUsForm';
import ContactUsInformation from '../components/ContactUs/ContactUsInformation';
import TermsAndCondition from '../components/Modal/TermsAndConditions';
import withLocale from '../redux/Hoc/withLocale';
import withTermsAndCondition from '../redux/Hoc/withTermsAndCondition';

const ContactUs = ({ termsAndCondition, locale }) => (
  <div className="main-section">
    <section className="container">
      <div className="conatct-page">
        <ContactUsForm />
        <ContactUsInformation />
      </div>
    </section>
    <TermsAndCondition termsAndCondition={termsAndCondition[locale]} />
  </div>
);

export default withLocale(withTermsAndCondition(ContactUs));
