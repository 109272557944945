import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import Home from './screens/Home';
// import Login from './screens/Login'; // Commented out the original import
// import SignUp from './screens/SignUp';
import ForgotPassword from './screens/ForgotPassword';
import ResetPassword from './screens/ResetPassword';
import Profile from './screens/Profile';
import Merchants from './screens/Merchants';
import MerchantsDetails from './screens/MerchantsDetails';
import ContactUs from './screens/ContactUs';
import TermsConditions from './screens/TermsConditions';
import PrivacyPolicy from './screens/PrivacyPolicy';

// import Partners from './screens/Partners';
import AboutUs from './screens/AboutUs';
import Layout from './Layout';
import NotFound from './screens/NotFound';
import Global from './components/Global';
import MbiInitiative from './screens/MbiInitiative';

const NO_AUTH = ['/auth', '/signup', '/forgot-pass', '/reset-pass'];
const PUBLIC_PATHS = [
  '/',
  '/merchants',
  '/merchants/:id',
  '/contact-us',
  '/terms-conditions',
  '/partners',
  '/about-us',
  '/profile',
  '/mbi-initiative',
  '/privacy-policy'
];
const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isInit, setIsInit] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  useEffect(() => {
    if (!isInit) {
      const currentUser = store.getState().user;
      setIsUserLoggedIn(currentUser.id);
      setIsInit(true);
    }
  }, [isInit]);
  const validateAuth = async () => {
    window.scrollTo(0, 0);
    if (!isUserLoggedIn) {
      navigate('/auth');
    }
  };

  const validateNoAuth = async () => {
    window.scrollTo(0, 0);
    if (isUserLoggedIn) {
      navigate('/');
    }
  };

  useEffect(() => {
    // CALL: when route change or initialize complete
    if (isInit) {
      const noAuthPath = NO_AUTH.filter((path) => location.pathname.indexOf(path) > -1);
      const publicPath = PUBLIC_PATHS.filter((path) => location.pathname.indexOf(path) > -1);
      if (noAuthPath.length > 0) {
        validateNoAuth();
      } else if (publicPath.length === 0) {
        const currentUser = store.getState().user;
        setIsUserLoggedIn(currentUser.id);
        validateAuth();
      }
    }
  }, [location, isInit]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Global />
        <Routes>
          <Route exact path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/auth" element={<LoginExternal />} /> {/* Replaced with LoginExternal */}
            <Route path="/signup" element={<SignUpExternal />} />
            <Route path="/forgot-pass" element={<ForgotPassword />} />
            <Route path="/reset-pass/:token" element={<ResetPassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/merchants" element={<Merchants />} />
            <Route path="/merchants/:id" element={<MerchantsDetails />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            {/* <Route path="/resources" element={<Partners />} /> */}
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/mbi-initiative" element={<MbiInitiative />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </PersistGate>
    </Provider>
  );
};

const LoginExternal = () => {
  useEffect(() => {
    // Redirect to external login page
    window.location.href = 'https://lamantest.com/plats/log-masuk-vendor/'; // Replace with external login URL
  }, []);

  return null; // Return nothing, component will not render
};

const SignUpExternal = () => {
  useEffect(() => {
    // Redirect to external signup page
    window.location.href = 'https://lamantest.com/plats/daftar-vendor-plats/'; // Replace with external signup URL
  }, []);

  return null; // Return nothing, component will not render
};

export default AppRoutes;
