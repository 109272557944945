import { connect } from 'react-redux';
import { customTypesSet } from '../actions';

const mapDispatchToProps = (dispatch) => ({
  setCustomTypes: (data) => {
    dispatch(customTypesSet(data));
  }
});

const mapStateToProps = (state) => ({
  customTypes: state.customTypes ? state.customTypes : []
});

export default connect(mapStateToProps, mapDispatchToProps);
