import { API_BASE_URL } from '../common/config';
import { store } from '../redux/store';
import { loaderSet, notifySet } from '../redux/actions';
import { convertFormData, queryString, userLogout } from '../common/global';

const isTokenExpire = async (responseJson) => {
  const response = await responseJson;
  if (response.message === 'Unauthorized request') {
    await userLogout();
    return;
  }
  return response;
};

const handleNetworkError = async (responseError) => {
  if (responseError.name !== 'AbortError') {
    store.dispatch(loaderSet(false));
    store.dispatch(
      notifySet({
        type: 'error',
        message: 'Network request error. Please try again.'
      })
    );
  }
};

export const login = async (data) =>
  fetch(`${API_BASE_URL}/user/auth`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    },
    body: JSON.stringify(data)
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const forgotPassword = async (data) =>
  fetch(`${API_BASE_URL}/user/forgot-pass`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    },
    body: JSON.stringify(data)
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const resetPassword = async (data) =>
  fetch(`${API_BASE_URL}/user/reset-pass`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    },
    body: JSON.stringify(data)
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const getAllCities = async () =>
  fetch(`${API_BASE_URL}/app/city`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    }
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const getAllCategories = async (type) =>
  fetch(`${API_BASE_URL}/app/category?type=${type}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    }
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const getAllSubCategories = async (type) =>
  fetch(`${API_BASE_URL}/app/sub-category?${queryString(type)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    }
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const getAllBusinessLicenseType = async () =>
  fetch(`${API_BASE_URL}/app/business-license-type`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    }
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const getAllFoodTypes = async () =>
  fetch(`${API_BASE_URL}/app/food-type`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    }
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const uploadImage = async (data, imageType) =>
  fetch(`${API_BASE_URL}/app/upload-image?${queryString(imageType)}`, {
    method: 'POST',
    headers: {
      Accept: 'application/x-www-form-urlencoded',
      language: store.getState().locale
    },
    body: await convertFormData({ file: data })
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const sendOtp = async (data) =>
  fetch(`${API_BASE_URL}/user/send-otp`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    },
    body: JSON.stringify(data)
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const verifyOTP = async (data) =>
  fetch(`${API_BASE_URL}/user/verify-otp`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    },
    body: JSON.stringify(data)
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const signup = async (data) =>
  fetch(`${API_BASE_URL}/user/signup`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    },
    body: JSON.stringify(data)
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const getAllVideos = async () =>
  fetch(`${API_BASE_URL}/app/videos`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    }
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const getAllMerchantsList = async (merchantFilter) =>
  fetch(`${API_BASE_URL}/merchant?${queryString(merchantFilter)}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    }
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));
export const getMerchantsDetails = async (id) =>
  fetch(`${API_BASE_URL}/merchant/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    }
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const getAllSettings = async (type) =>
  fetch(`${API_BASE_URL}/settings/${type}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    }
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const subscribe = async (data) =>
  fetch(`${API_BASE_URL}/subscribe`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    },
    body: JSON.stringify(data)
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const getAllSliders = async () =>
  fetch(`${API_BASE_URL}/sliders`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    }
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const getAllFaqList = async () =>
  fetch(`${API_BASE_URL}/faqs`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    }
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const contactUs = async (data) =>
  fetch(`${API_BASE_URL}/app/contact-us`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    },
    body: JSON.stringify(data)
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const getProfile = async () =>
  fetch(`${API_BASE_URL}/user/profile`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale,
      Authorization: store.getState().user.token
    }
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const updateProfile = async (data) =>
  fetch(`${API_BASE_URL}/user/profile`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale,
      Authorization: store.getState().user.token
    },
    body: JSON.stringify(data)
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));

export const getAllCustomTypes = async () =>
  fetch(`${API_BASE_URL}/app/slider-custom-type`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      language: store.getState().locale
    }
  })
    .then(async (response) => isTokenExpire(response.json()))
    .catch((error) => handleNetworkError(error));
