import moment from 'moment';
import { store } from '../redux/store';
import { notifySet, resetRedux } from '../redux/actions';
import i18n from '../i18n';

// REMOVE USER FROM REDUX & LOCAL STORAGE
export const userLogout = async () => {
  setTimeout(() => {
    store.dispatch(resetRedux());
    store.dispatch(notifySet({ type: 'success', message: 'You have successfully signed out.' }));
    window.location = '/auth';
  }, 500);
};

export const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
};

export const convertFormData = async (data) => {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
};

// CONVERT OBJECT TO QUERY STRING
export const queryString = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export const imageUploadType = {
  PROFILE: 'profile',
  GALLERY: 'gallery',
  MENU: 'menu'
};

export const weekDays = [
  i18n.t('days.sunday'),
  i18n.t('days.monday'),
  i18n.t('days.tuesday'),
  i18n.t('days.wednesday'),
  i18n.t('days.thursday'),
  i18n.t('days.friday'),
  i18n.t('days.saturday')
];

export const getTimeSlots = () => {
  let slotTime = moment('00:00', 'HH:mm');
  const endTime = moment('23:59', 'HH:mm');

  const times = [];
  while (slotTime < endTime) {
    times.push(slotTime.format('hh:mm A'));
    slotTime = slotTime.add(15, 'minutes');
  }
  return times;
};
