import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../../common/config';
import * as appImages from '../../assets/images';

const PlatListBlock = ({ platsMerchants, onMerchantList, errMsg, viewMore, totalCount }) => {
  const { t } = useTranslation();
  return (
    <div className="platslist-block">
      <h2 className="section-title">{t('merchants.platsMerchants')}</h2>
      <div className="platslist-row row">
        {!_.isEmpty(platsMerchants) ? (
          platsMerchants.map((merchant, key) => (
            <div className="col-xl-2 col-lg-3 col-md-4 col-6" key={key}>
              <a
                href="javascript:void(0);"
                className="food-card"
                onClick={() => onMerchantList(merchant)}>
                <div className="food-img">
                  <img
                    src={`${BASE_URL}${merchant.image}`}
                    alt="image"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = `${appImages.profile}`;
                    }}
                  />
                </div>
                <div className="food-text">
                  <h4>{merchant.businessName}</h4>
                </div>
              </a>
            </div>
          ))
        ) : (
          <h5 className="more-plats error-msg">{errMsg ? t('merchants.noDataErrorMsg') : ''}</h5>
        )}
      </div>
      <div className="text-center">
        {platsMerchants.length > 0 && platsMerchants.length < totalCount && (
          <button type="button" className="btn secondary" onClick={() => viewMore()}>
            {t('merchants.viewMore')}
          </button>
        )}
      </div>
    </div>
  );
};
export default PlatListBlock;
