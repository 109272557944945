import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import * as appImages from '../../assets/images';
import { getAllCities } from '../../utils/API';
import { GOOGLE_MAP_KEY } from '../../common/config';

const MerchantDetailInfo = ({ platsMerchantDetails }) => {
  const { t } = useTranslation();
  const [city, setCity] = useState('');
  const workingHours = JSON.parse(platsMerchantDetails.workingTime);
  const days = [
    t('days.sunday'),
    t('days.monday'),
    t('days.tuesday'),
    t('days.wednesday'),
    t('days.thursday'),
    t('days.friday'),
    t('days.saturday')
  ];
  const [currentDayData, setCurrentDayData] = useState({});

  const getCities = async () => {
    const citiesRes = await getAllCities();
    const { status, data } = citiesRes;
    if (status) {
      setCity(_.find(data, ['id', platsMerchantDetails.cityId]).title);
    }
  };

  const getCurrentDayData = () => {
    Object.keys(workingHours).map(() => {
      setCurrentDayData(workingHours[moment().weekday()]);
    });
  };

  useEffect(() => {
    getCities();
    getCurrentDayData();
  }, []);

  return (
    <div className="merchantdetail-right">
      <div className="d-none d-md-block">
        <h2>{platsMerchantDetails.businessName.toUpperCase()}</h2>
        <div className="media-share">
          <ul>
            {!_.isEmpty(platsMerchantDetails.facebook) && (
              <li>
                <a href={platsMerchantDetails.facebook} target="_blank" rel="noreferrer">
                  <img src={appImages.facebookIco} alt="image" />
                </a>
              </li>
            )}
            {!_.isEmpty(platsMerchantDetails.twitter) && (
              <li>
                <a href={platsMerchantDetails.twitter} target="_blank" rel="noreferrer">
                  <img src={appImages.twitterIco} alt="image" />
                </a>
              </li>
            )}
            {!_.isEmpty(platsMerchantDetails.instagram) && (
              <li>
                <a href={platsMerchantDetails.instagram} target="_blank" rel="noreferrer">
                  <img src={appImages.instagramIco} alt="image" />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div>
        <p className="merchantdetail-disc">{platsMerchantDetails.description}</p>
      </div>
      <div className="merchantdetail-location">
        <div className="conatct-map">
          <iframe
            title={platsMerchantDetails.businessName}
            src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAP_KEY}&q=${platsMerchantDetails.lat},${platsMerchantDetails.lng}`}
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="address">
          <ul>
            <li className="address-ico">{city}</li>
            <li className="address-ico">{platsMerchantDetails.addressLine1}</li>
            <li className="phone-ico">+60 {platsMerchantDetails.contactInfo}</li>
          </ul>
        </div>
      </div>
      {platsMerchantDetails.isOperationalHours && (
        <div className="merchantdetail-info">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#collapsedetailinfo"
            aria-expanded="false"
            aria-controls="collapsedetailinfo"
            className="collapse-merchant-info">
            <div className="col-5 p-0">
              <span>{days[moment().weekday()]}</span>
            </div>
            <div className="col-5 p-0">
              <div className="text-center">
                {currentDayData.isOpen
                  ? `${currentDayData.from} ${t('signUp.to')} ${currentDayData.to}`
                  : `${t('merchantDetails.holiday')}`}
              </div>
            </div>
            <div className="col-2 text-end">
              <img src={appImages.accordianArrow} alt="icon" />
            </div>
          </div>
          <ul className="detailinfo-list collapse" id="collapsedetailinfo">
            {Object.keys(workingHours).map((item, key) => (
              <li key={key}>
                <strong>{days[key]}</strong>{' '}
                <span className="right">
                  {workingHours[key].isOpen
                    ? `${workingHours[key].from} ${t('signUp.to')} ${workingHours[key].to}`
                    : `${t('merchantDetails.holiday')}`}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="merchantdetail-btn">
        {!_.isEmpty(platsMerchantDetails.orderLink) ? (
          <a
            href={platsMerchantDetails.orderLink}
            className="order-now"
            target="_blank"
            rel="noreferrer">
            {' '}
            <img src={appImages.detailCall} alt="icon" /> {t('merchantDetails.orderNow')}
          </a>
        ) : (
          <a
            href={platsMerchantDetails.orderLink}
            className="disabled"
            target="_blank"
            rel="noreferrer">
            {' '}
            <img src={appImages.detailCallDisabled} alt="icon" /> {t('merchantDetails.orderNow')}
          </a>
        )}

        {!_.isEmpty(platsMerchantDetails.whatsappNumber) ? (
          <a
            href={`https://wa.me/60${platsMerchantDetails.whatsappNumber}`}
            className="whatsapp-order"
            target="_blank"
            rel="noreferrer">
            {' '}
            <img src={appImages.detailCall} alt="icon" /> {t('merchantDetails.wpOrder')}
          </a>
        ) : (
          <a
            href={`https://wa.me/60${platsMerchantDetails.whatsappNumber}`}
            className="disabled"
            target="_blank"
            rel="noreferrer">
            {' '}
            <img src={appImages.detailCallDisabled} alt="icon" /> {t('merchantDetails.wpOrder')}
          </a>
        )}
      </div>
    </div>
  );
};

export default MerchantDetailInfo;
