import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import _ from 'lodash';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { useTranslation } from 'react-i18next';
import { renderField } from '../../helpers/form-validations';
import { getAllFoodTypes, uploadImage } from '../../utils/API';
import { imageUploadType } from '../../common/global';
import { BASE_URL } from '../../common/config';

const Menu = forwardRef(
  ({ setLoader, setNotify, onFormSubmit, profileDetails, isProfile = false }, ref) => {
    const { t } = useTranslation();
    const addRowRef = useRef(null);
    const [foodTypes, setFoodTypes] = useState([]);
    const submitBtnRef = useRef();
    const [menus, setMenus] = useState([]);

    useEffect(() => {
      if (isProfile) {
        const oueMenu = profileDetails.business.businessMenus;
        oueMenu.forEach((object) => {
          delete object.businessId;
          delete object.createdAt;
          delete object.updatedAt;
          delete object.deletedAt;
          delete object.id;
        });
        setMenus(oueMenu);
      }
    }, []);

    const onSubmit = (formData) => {
      if (formData.hasOwnProperty('menu') && formData.menu.length === 0) {
        delete formData.menu;
      }
      onFormSubmit(formData);
    };

    const getFoodTypes = async () => {
      const foodTypeRes = await getAllFoodTypes();
      setFoodTypes(foodTypeRes.data);
    };

    useImperativeHandle(ref, () => ({
      onMenuParentSubmit() {
        submitBtnRef.current.click();
      }
    }));

    useEffect(() => {
      getFoodTypes();
      setTimeout(() => {
        window.loadSelectPicker('.foodtype-select');
      }, 2000);
    }, []);

    const imageUpload = async (imageData, type) => {
      setLoader(true);
      if (imageData.files && imageData.files[0]) {
        const profileImageRes = await uploadImage(imageData.files[0], type);
        const { status, data } = profileImageRes;
        setLoader(false);
        if (status) {
          return data;
        }
        setNotify({ message: 'Fail to upload profile', type: 'error' });
        return false;
      }
    };
    const onAddMenuImage = async (event, { value }, index, form) => {
      value[index].image = await imageUpload(event, { type: imageUploadType.MENU });
      form.mutators.update(`menu`, value, value[index]);
    };

    const onDeleteImage = async ({ value }, index, form) => {
      value[index].image = '';
      form.mutators.update(`menu`, value, value[index]);
    };

    return (
      <div className="information-row merchantinfosection" id="infomenu">
        <h2 className="section-title">{t('signUp.sideMenu.menu')}</h2>
        <Form
          initialValues={
            isProfile
              ? {
                  menu: menus
                }
              : {}
          }
          onSubmit={onSubmit}
          mutators={{
            ...arrayMutators,
            update: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            }
          }}
          render={({ handleSubmit, values: { menu }, form }) => (
            <form onSubmit={handleSubmit}>
              <FieldArray name="menu">
                {({ fields }) => (
                  <>
                    {fields.map((name, index) => (
                      <div key={index} className="infoitem-card">
                        <button
                          type="button"
                          className="infoitem-close close-item-btn"
                          onClick={() => fields.remove(index)}
                        />
                        <h3>
                          {t('signUp.menu.item')} {index + 1}
                        </h3>
                        <div className="row" key={index}>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{t('signUp.menu.productName')}</label>
                              <Field
                                className="form-control"
                                name={`${name}.title`}
                                type="text"
                                placeholder={t('signUp.menu.dishName')}>
                                {renderField}
                              </Field>
                            </div>
                          </div>
                          {!_.isEmpty(foodTypes) && (
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>{t('signUp.menu.foodType')}</label>
                                <Field
                                  component="select"
                                  name={`${name}.foodTypeId`}
                                  className="foodtype-select boot-select"
                                  data-live-search="false">
                                  {foodTypes.map((foodType) => (
                                    <option key={foodType.id} value={foodType.id}>
                                      {foodType.title}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                            </div>
                          )}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{t('signUp.menu.price')} (RM)</label>
                              <Field
                                className="form-control"
                                name={`${name}.price`}
                                type="text"
                                placeholder={t('signUp.menu.dishPrice')}>
                                {renderField}
                              </Field>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{t('signUp.menu.productImage')}</label>
                              {menu[index].image !== '' ? (
                                <div className="uploadimg-card gallery-image-view">
                                  <button
                                    type="button"
                                    className="infoitem-close"
                                    onClick={() => onDeleteImage(fields, index, form)}
                                  />
                                  <img
                                    className="gallery-image"
                                    src={`${BASE_URL}${menu[index].image}`}
                                    alt="itemMenuImage"
                                  />
                                </div>
                              ) : (
                                <>
                                  <div className="uploadimg-card">
                                    <div className="btn secondary">
                                      <input
                                        type="file"
                                        name="file"
                                        value=""
                                        accept="image/*"
                                        onChange={(e) =>
                                          onAddMenuImage(e.target, fields, index, form)
                                        }
                                      />
                                      <span>
                                        {t('signUp.imageUpload.upload')}{' '}
                                        {t('signUp.imageUpload.img')}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <span
                      ref={addRowRef}
                      className="btn primary btn-item"
                      onClick={() => {
                        fields.push({
                          title: '',
                          foodTypeId: foodTypes.length > 0 ? foodTypes[0].id : '',
                          price: '',
                          image: ''
                        });
                        setTimeout(() => {
                          window.loadSelectPicker('.foodtype-select');
                        }, 100);
                      }}>
                      {`+ ${t('signUp.menu.newItem')}`}
                    </span>
                    <button ref={submitBtnRef} className="visually-hidden" type="submit">
                      {t('signUp.businessInformation.save')}
                    </button>
                  </>
                )}
              </FieldArray>
            </form>
          )}
        />
      </div>
    );
  }
);
export default Menu;
