import { connect } from 'react-redux';
import { privacyPolicySet } from '../actions';

const mapDispatchToProps = (dispatch) => ({
  setPrivacyPolicy: (data) => {
    dispatch(privacyPolicySet(data));
  }
});

const mapStateToProps = (state) => ({
  privacyPolicy: state.privacyPolicy ? state.privacyPolicy : null
});

export default connect(mapStateToProps, mapDispatchToProps);
