import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import * as appImages from '../../assets/images';
import { composeValidators, link, renderField } from '../../helpers/form-validations';

const SocialMedia = forwardRef(({ onFormSubmit, profileDetails, isProfile = false }, ref) => {
  const { t } = useTranslation();
  const submitBtnRef = useRef();
  const onSubmit = (formData) => {
    onFormSubmit(formData);
  };
  useImperativeHandle(ref, () => ({
    onSocialMediaParentSubmit() {
      submitBtnRef.current.click();
    }
  }));
  return (
    <div className="information-row merchantinfosection" id="socialmedia">
      <h2 className="section-title">{t('signUp.sideMenu.socialMedia')}</h2>
      <Form
        onSubmit={onSubmit}
        initialValues={
          isProfile
            ? {
                facebook: profileDetails.business.facebook,
                twitter: profileDetails.business.twitter,
                instagram: profileDetails.business.instagram
              }
            : {}
        }
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    <img src={appImages.facebookIco} alt="icon" /> Facebook
                  </label>
                  <Field
                    className="form-control"
                    name="facebook"
                    type="text"
                    placeholder="http://facebook.com/merchant"
                    validate={composeValidators(link)}>
                    {renderField}
                  </Field>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    <img src={appImages.twitterIco} alt="icon" /> Twitter
                  </label>

                  <Field
                    className="form-control"
                    name="twitter"
                    type="text"
                    placeholder="http://twitter.com/merchant"
                    validate={composeValidators(link)}>
                    {renderField}
                  </Field>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    <img src={appImages.instagramIco} alt="icon" /> Instagram
                  </label>
                  <Field
                    className="form-control"
                    name="instagram"
                    type="text"
                    placeholder="http://instagram.com/merchant"
                    validate={composeValidators(link)}>
                    {renderField}
                  </Field>
                </div>
              </div>
              <div className="col-md-4 visually-hidden">
                <div className="form-group">
                  <button ref={submitBtnRef} type="submit">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
});
export default SocialMedia;
