import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import withLoader from '../redux/Hoc/withLoader';
import withNotify from '../redux/Hoc/withNotify';
import Loader from './Loader';
import withLocale from '../redux/Hoc/withLocale';

const Global = ({ loader, notify, locale }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  useEffect(() => {
    if (notify) {
      toast(notify.message, {
        type: notify.type,
        icon: true,
        position: 'top-center',
        theme: 'colored'
      });
    }
  }, [notify]);

  return (
    <>
      <ToastContainer />
      {loader && <Loader />}
    </>
  );
};

export default withLocale(withLoader(withNotify(Global)));
