import {
  IS_ADVANCE_SEARCH_SET,
  LOADER_SET,
  LOCALE_SET,
  MERCHANT_FILTER_SET,
  NOTIFY_SET,
  RESET_REDUX,
  TERMS_AND_CONDITION_SET,
  USER_SET,
  CITIES_SET,
  BUSINESS_LICENSE_TYPE_SET,
  FOOD_TYPE_SET,
  SUB_CATEGORIES_SET,
  CATEGORIES_SET,
  CUSTOM_TYPES_SET,
  PRIVACY_POLICY_SET
} from './actions';
import * as config from '../common/config';

export const DEFAULT_USER = {
  id: null,
  token: null
};

export const DEFAULT_MERCHANT_FILTER = {
  text: '',
  city: [],
  foodType: [],
  categoryTypes: [],
  subCategoryTypes: [],
  licenseTypes: [],
  sliderCustomTypes: [],
  businessMerchantType: ''
};

export const loader = (state = false, action) => {
  switch (action.type) {
    case LOADER_SET:
      return action.state;

    default:
      return state;
  }
};

export const notify = (state = {}, action) => {
  switch (action.type) {
    case NOTIFY_SET:
      return action.state;

    default:
      return state;
  }
};

export const locale = (state = config.DEFAULT_LOCALE, action) => {
  switch (action.type) {
    case LOCALE_SET:
      return action.state;

    default:
      return state;
  }
};

export const user = (state = DEFAULT_USER, action) => {
  switch (action.type) {
    case USER_SET:
      return action.state;
    case RESET_REDUX: {
      const userData = DEFAULT_USER;
      return userData;
    }

    default:
      return state;
  }
};

export const merchantFilter = (state = DEFAULT_MERCHANT_FILTER, action) => {
  switch (action.type) {
    case MERCHANT_FILTER_SET:
      return action.state;
    case RESET_REDUX: {
      const merchantData = DEFAULT_MERCHANT_FILTER;
      return merchantData;
    }
    default:
      return state;
  }
};

export const termsAndCondition = (state = {}, action) => {
  switch (action.type) {
    case TERMS_AND_CONDITION_SET:
      return action.state;

    default:
      return state;
  }
};

export const isAdvanceSearch = (state = false, action) => {
  switch (action.type) {
    case IS_ADVANCE_SEARCH_SET:
      return action.state;

    default:
      return state;
  }
};

export const cities = (state = [], action) => {
  switch (action.type) {
    case CITIES_SET:
      return action.state;

    default:
      return state;
  }
};

export const businessLicenseTypes = (state = [], action) => {
  switch (action.type) {
    case BUSINESS_LICENSE_TYPE_SET:
      return action.state;

    default:
      return state;
  }
};

export const foodTypes = (state = [], action) => {
  switch (action.type) {
    case FOOD_TYPE_SET:
      return action.state;

    default:
      return state;
  }
};

export const subCategories = (state = [], action) => {
  switch (action.type) {
    case SUB_CATEGORIES_SET:
      return action.state;

    default:
      return state;
  }
};

export const categories = (state = [], action) => {
  switch (action.type) {
    case CATEGORIES_SET:
      return action.state;

    default:
      return state;
  }
};

export const customTypes = (state = [], action) => {
  switch (action.type) {
    case CUSTOM_TYPES_SET:
      return action.state;

    default:
      return state;
  }
};

export const privacyPolicy = (state = {}, action) => {
  switch (action.type) {
    case PRIVACY_POLICY_SET:
      return action.state;

    default:
      return state;
  }
};
