import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import PlatListFilter from '../components/Merchants/PlatListFilter';
import PlatListBlock from '../components/Merchants/PlatListBlock';
import { getAllMerchantsList } from '../utils/API';
import withLoader from '../redux/Hoc/withLoader';
import withNotify from '../redux/Hoc/withNotify';
import withLocale from '../redux/Hoc/withLocale';
import withMerchantFilter from '../redux/Hoc/withMerchantFilter';
import PlatsDirectory from '../components/Home/PlatsDirectory';
import withCities from '../redux/Hoc/withCities';
import withBusinessLicenseTypes from '../redux/Hoc/withBusinessLicenseTypes';
import withFoodTypes from '../redux/Hoc/withFoodTypes';
import withSubCategories from '../redux/Hoc/withSubCategories';
import withCategories from '../redux/Hoc/withCategories';
import withCustomTypes from '../redux/Hoc/withCustomTypes';
import { DEFAULT_MERCHANT_FILTER } from '../redux/reducers';

const Merchants = ({
  setNotify,
  merchantFilter,
  setMerchantFilter,
  cities,
  businessLicenseTypes,
  foodTypes,
  subCategories,
  categories,
  customTypes,
  setLoader
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [platsMerchants, setPlatsMerchants] = useState([]);
  const [errMsg, setErrMsg] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [searchData, setSearchData] = useState(undefined);

  const getPlatsMerchantsList = async ({ page: currentPage = 0, count = 1 }) => {
    const {
      city,
      categoryTypes,
      foodType,
      subCategoryTypes,
      sliderCustomTypes,
      text,
      licenseTypes,
      businessMerchantType
    } = merchantFilter;
    setLoader(true);
    const merchantListPayload = {
      text: !_.isEmpty(text) ? text : '',
      city: !_.isEmpty(city) ? city.join(',') : '',
      foodType: !_.isEmpty(foodType) ? foodType.join(',') : '',
      categoryTypes: !_.isEmpty(categoryTypes) ? categoryTypes.join(',') : '',
      subCategoryTypes: !_.isEmpty(subCategoryTypes) ? subCategoryTypes.join(',') : '',
      licenseTypes: !_.isEmpty(licenseTypes) ? licenseTypes.join(',') : '',
      sliderCustomTypes: !_.isEmpty(sliderCustomTypes) ? sliderCustomTypes.join(',') : '',
      businessMerchantType: businessMerchantType || '',
      page: currentPage,
      count
    };

    const merchantListRes = await getAllMerchantsList(merchantListPayload);

    const { data, status, message } = merchantListRes;
    if (merchantListPayload.page === 0) {
      window.scroll(0, 0);
    }
    if (status) {
      setLoader(false);
      if (_.isEmpty(data)) {
        setPlatsMerchants(data);
        setErrMsg(true);
      } else if (currentPage === 0) {
        setTotalCount(merchantListRes.count);
        setPlatsMerchants(data);
      } else {
        setPlatsMerchants(_.unionBy([].concat(platsMerchants, data), 'id'));
      }
    } else {
      setLoader(false);
      setNotify({ message, type: 'error' });
    }
  };

  useEffect(() => {
    if (_.isEmpty(merchantFilter)) {
      setSearchData(DEFAULT_MERCHANT_FILTER);
    } else {
      setSearchData(merchantFilter);
    }
  }, []);

  useEffect(() => {
    getPlatsMerchantsList({ page: 0, count: 1 });
  }, [merchantFilter]);

  const onClickMerchant = (selectedMerchant) => {
    navigate(
      `/merchants/${
        selectedMerchant.whatsappNumber || selectedMerchant.contactInfo || selectedMerchant.id
      }`
    );
  };

  const onSelectFilterValues = (selectedFilterValues, type) => {
    const oldMerchantFilterData = searchData;

    if (type === 'city') {
      setSearchData({
        ...oldMerchantFilterData,
        city: _.isEmpty(selectedFilterValues)
          ? selectedFilterValues
          : _.uniq([].concat(...oldMerchantFilterData.city, selectedFilterValues))
      });
    } else if (type === 'foodType') {
      setSearchData({
        ...oldMerchantFilterData,
        foodType: _.isEmpty(selectedFilterValues) ? selectedFilterValues : selectedFilterValues
      });
    } else if (type === 'categoryTypes') {
      setSearchData({
        ...oldMerchantFilterData,
        categoryTypes: _.isEmpty(selectedFilterValues)
          ? selectedFilterValues
          : _.uniq([].concat(...oldMerchantFilterData.categoryTypes, selectedFilterValues))
      });
    } else if (type === 'subCategoryTypes') {
      setSearchData({
        ...oldMerchantFilterData,
        subCategoryTypes: _.isEmpty(selectedFilterValues)
          ? selectedFilterValues
          : _.uniq([].concat(...oldMerchantFilterData.subCategoryTypes, selectedFilterValues))
      });
    } else if (type === 'licenseTypes') {
      setSearchData({
        ...oldMerchantFilterData,
        licenseTypes: _.isEmpty(selectedFilterValues)
          ? selectedFilterValues
          : _.uniq([].concat(...oldMerchantFilterData.licenseTypes, selectedFilterValues))
      });
    } else if (type === 'sliderCustomTypes') {
      setSearchData({
        ...oldMerchantFilterData,
        sliderCustomTypes: _.isEmpty(selectedFilterValues)
          ? selectedFilterValues
          : _.uniq([].concat(...oldMerchantFilterData.sliderCustomTypes, selectedFilterValues))
      });
    }
  };

  const onClickSearch = () => {
    setMerchantFilter(searchData);
  };

  const viewMore = async () => {
    setPage(page + 1);
    await getPlatsMerchantsList({ page: page + 1, count: 0 });
  };

  useEffect(
    () => () => {
      setMerchantFilter({});
    },
    []
  );

  return (
    <div className="main-section">
      <section className="platslist-page">
        <div className="container">
          <PlatListFilter
            location={cities}
            businessLicenseTypes={businessLicenseTypes}
            foodTypes={foodTypes}
            subCategories={subCategories}
            categories={categories}
            customTypes={customTypes}
            onSelectFilterValues={(selectedFilterValues, type) =>
              onSelectFilterValues(selectedFilterValues, type)
            }
          />
          <div className="search-btn-sec">
            <button className="btn primary" onClick={() => onClickSearch()} type="button">
              {t('layout.searchText')}
            </button>
          </div>
          <PlatListBlock
            platsMerchants={platsMerchants}
            onMerchantList={(selectedMerchant) => onClickMerchant(selectedMerchant)}
            errMsg={errMsg}
            totalCount={totalCount}
            viewMore={() => viewMore()}
          />
        </div>
      </section>
      <section className="">
        <PlatsDirectory />
      </section>
    </div>
  );
};

export default withBusinessLicenseTypes(
  withSubCategories(
    withCategories(
      withCustomTypes(
        withFoodTypes(withCities(withLoader(withNotify(withMerchantFilter(withLocale(Merchants))))))
      )
    )
  )
);
