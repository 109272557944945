import React, { useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import withLocale from '../redux/Hoc/withLocale';
import i18n from '../i18n';

const PlatListFilter = ({
  location,
  businessLicenseTypes,
  foodTypes,
  subCategories,
  categories,
  onSelectFilterValues,
  advanceSearchData,
  locale,
  customTypes,
  isHome = false
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.loadSelectPicker('.location-select', {
      noneSelectedText: i18n.t('validation.nothingSelected')
    });
    window.loadSelectPicker('.businessLicenseType-select', {
      noneSelectedText: i18n.t('validation.nothingSelected')
    });
    window.loadSelectPicker('.foodType-select', {
      noneSelectedText: i18n.t('validation.nothingSelected')
    });
    window.loadSelectPicker('.sub-category-select', {
      noneSelectedText: i18n.t('validation.nothingSelected')
    });
    window.loadSelectPicker('.category-select', {
      noneSelectedText: i18n.t('validation.nothingSelected')
    });
    window.loadSelectPicker('.customTypes-select', {
      noneSelectedText: i18n.t('validation.nothingSelected')
    });
  }, [locale]);

  useEffect(() => {
    window.$('.location-select').selectpicker('refresh');
    window.$('.businessLicenseType-select').selectpicker('refresh');
    window.$('.foodType-select').selectpicker('refresh');
    window.$('.sub-category-select').selectpicker('refresh');
    window.$('.category-select').selectpicker('refresh');
    window.$('.customTypes-select').selectpicker('refresh');
  }, [locale, advanceSearchData]);

  const onHandleChange = (e, type) => {
    const options = e.target.options;
    const value = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    onSelectFilterValues(value, type);
  };

  return (
    <div className={`platslist-filter ${isHome ? 'justify-content-end text-start' : ''}`}>
      {!_.isEmpty(location) && (
        <div className="form-group">
          <label className="fw-bold">{t('signUp.businessInformation.city')}</label>
          <select
            component="select"
            id="locationId"
            name="location"
            className="location-select boot-select"
            multiple
            onChange={(event) => onHandleChange(event, 'city')}
            initialValue={location.length > 0 ? location[0].id : ''}>
            {location.map((city) => (
              <option
                key={city.id}
                value={city.id}
                selected={advanceSearchData.city.indexOf(city.id) > -1}>
                {city.title}
              </option>
            ))}
          </select>
        </div>
      )}
      {!_.isEmpty(businessLicenseTypes) && (
        <div className="form-group">
          <label className="fw-bold">{t('signUp.businessInformation.bLicenseType')}</label>
          <select
            component="select"
            name="businessLicenseType"
            id="businessLicenseTypeId"
            className="businessLicenseType-select boot-select"
            multiple
            onChange={(event) => onHandleChange(event, 'licenseTypes')}
            initialValue={businessLicenseTypes.length > 0 ? businessLicenseTypes[0].id : ''}>
            {businessLicenseTypes.map((businessLicenseType) => (
              <option
                key={businessLicenseType.id}
                value={businessLicenseType.id}
                selected={advanceSearchData.licenseTypes.indexOf(businessLicenseType.id) > -1}>
                {businessLicenseType.title}
              </option>
            ))}
          </select>
        </div>
      )}

      {!_.isEmpty(categories) && (
        <div className="form-group">
          <label className="fw-bold">{t('signUp.businessInformation.category')}</label>
          <select
            component="select"
            name="category"
            id="categoryId"
            className="category-select boot-select"
            multiple
            onChange={(event) => onHandleChange(event, 'categoryTypes')}
            initialValue={categories.length > 0 ? categories[0].id : ''}>
            {categories.map((category) => (
              <option
                key={category.id}
                value={category.id}
                selected={advanceSearchData.categoryTypes.indexOf(category.id) > -1}>
                {category.title}
              </option>
            ))}
          </select>
        </div>
      )}

      {!_.isEmpty(subCategories) && (
        <div className="form-group">
          <label className="fw-bold">{t('signUp.businessInformation.subCategory')}</label>
          <select
            component="select"
            name="subCategory"
            id="subCategoryId"
            className="sub-category-select boot-select"
            multiple
            onChange={(event) => onHandleChange(event, 'subCategoryTypes')}
            initialValue={subCategories.length > 0 ? subCategories[0].id : ''}>
            {subCategories.map((category) => (
              <option
                key={category.id}
                value={category.id}
                selected={advanceSearchData.subCategoryTypes.indexOf(category.id) > -1}>
                {category.title}
              </option>
            ))}
          </select>
        </div>
      )}
      {!_.isEmpty(foodTypes) && (
        <div className="form-group">
          <label className="fw-bold">{t('signUp.menu.foodType')}</label>
          <select
            component="select"
            name="foodType"
            id="foodTypeId"
            className="foodType-select boot-select"
            multiple
            onChange={(event) => onHandleChange(event, 'foodType')}
            data-live-search="false">
            {foodTypes.map((foodType) => (
              <option
                key={foodType.id}
                value={foodType.id}
                selected={advanceSearchData.foodType.indexOf(foodType.id) > -1}>
                {foodType.title}
              </option>
            ))}
          </select>
        </div>
      )}
      {!_.isEmpty(customTypes) && (
        <div className="form-group">
          <label className="fw-bold">{t('merchants.customTypes')}</label>
          <select
            component="select"
            name="customTypes"
            id="customTypesId"
            className="customTypes-select boot-select"
            multiple
            onChange={(event) => onHandleChange(event, 'sliderCustomTypes')}
            data-live-search="false"
            initialValue={customTypes.length > 0 ? customTypes[0].id : ''}>
            {customTypes.map((customType) => (
              <option
                key={customType.id}
                value={customType.id}
                selected={advanceSearchData.sliderCustomTypes.indexOf(customType.id) > -1}>
                {customType.title}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
};
export default withLocale(PlatListFilter);
