import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import GoogleMapReact from 'google-map-react';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_MAP_KEY } from '../../common/config';
import { getAddressObjFromGeocodeResponse } from '../../helpers/app';

Geocode.setApiKey(GOOGLE_MAP_KEY);
Geocode.setRegion('my');

const Map = ({ center, height, onPlaceSelected, location }) => {
  const [mapMarker, setMapMarker] = useState({
    lat: center.lat,
    lng: center.lng,
    x: 0,
    y: 0
  });
  const [googleMarker, setGoogleMarker] = useState(null);
  const [address, setAddress] = useState(location);
  const [mapState, setMapState] = useState({ map: null, maps: null });
  const [isClick, setIsClick] = useState(false);

  const onSelectPlace = (place) => {
    const latValue = place.geometry.location.lat();
    const lngValue = place.geometry.location.lng();
    setMapMarker({
      lat: latValue,
      lng: lngValue
    });
    setAddress(place.formatted_address);
  };

  useEffect(() => {
    if (mapState.maps && mapMarker) {
      if (googleMarker) {
        googleMarker.setMap(null);
      }
      setGoogleMarker(
        new mapState.maps.Marker({
          // Create a new marker and use the map's center as the location.
          position: {
            lat: parseFloat(mapMarker.lat),
            lng: parseFloat(mapMarker.lng)
          },
          map: mapState.map,
          title: ''
        })
      );
      Geocode.fromLatLng(mapMarker.lat, mapMarker.lng).then(
        (response) => {
          const addressObj = getAddressObjFromGeocodeResponse(response);
          setAddress(addressObj.address);
          onPlaceSelected({ ...addressObj, ...mapMarker }, isClick);
        },
        () => {
          // console.error(error);
        }
      );
    }
  }, [mapMarker, mapState]);

  return center.lat !== undefined ? (
    <div style={{ height: '300px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
        defaultCenter={{ lat: 3.140853, lng: 101.693207 }}
        onGoogleApiLoaded={({ map, maps }) => setMapState({ map, maps })}
        onClick={(place) => {
          setIsClick(true);
          setMapMarker(place);
        }}
        center={{ lat: mapMarker.lat, lng: mapMarker.lng }}
        defaultZoom={14}
        yesIWantToUseGoogleMapApiInternals
      />
      <Autocomplete
        apiKey={GOOGLE_MAP_KEY}
        defaultValue={address}
        inputAutocompleteValue={address}
        style={{
          width: '100%',
          height: '40px'
        }}
        onPlaceSelected={onSelectPlace}
        options={{
          types: ['restaurant', 'store', 'cafe', 'supermarket'],
          componentRestrictions: { country: 'my' }
        }}
      />
    </div>
  ) : (
    <div style={{ height }} />
  );
};
export default Map;
