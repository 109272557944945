import React from 'react';
import { useTranslation } from 'react-i18next';
import * as appImages from '../../assets/images/index';

const IntroPlats = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <h2 className="section-title">{t('aboutUs.intro')}</h2>
      <div className="aboutblock-card">
        <h2>{t('about.plats.about')}</h2>
        <p>{t('about.plats.aboutData')}</p>
        <div className="aboutblock-img">
          <img src={appImages.aboutImg1} alt="aboutImg1" />
        </div>
        <p>{t('about.plats.aboutData1')}</p>
        <h6 className="mb-4">{t('about.plats.aboutData2')}</h6>
        <h6>{t('about.plats.aboutDataStep1B')}</h6>
        <p>{t('about.plats.aboutDataStep1')}</p>
        <h6>{t('about.plats.aboutDataStep2B')}</h6>
        <p>{t('about.plats.aboutDataStep2')}</p>
        <h6>{t('about.plats.aboutDataStep3B')}</h6>
        <p>{t('about.plats.aboutDataStep3')}</p>
      </div>
    </div>
  );
};

export default IntroPlats;
