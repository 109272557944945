import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const TermsAndCondition = ({ termsAndCondition }) => {
  const { t } = useTranslation();
  useEffect(() => {
    new window.Swiper('.termcondition-scroll', {
      direction: 'vertical',
      slidesPerView: 'auto',
      freeMode: true,
      scrollbar: {
        el: '.swiper-scrollbar'
      },
      mousewheel: true
    });
  }, []);

  return (
    <div
      className="modal fade termcondition-modal"
      id="termconditionmodal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
          <div className="modal-body">
            <h2>{t('layout.t&c')}</h2>
            <div className="termcondition-scroll swiper mySwiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <h4>{t('signUp.confirmation.disclaimer')}:</h4>
                  <p dangerouslySetInnerHTML={{ __html: termsAndCondition }} />
                </div>
              </div>
              <div className="swiper-scrollbar" />
            </div>
            <button type="submit" className="btn primary btn-submit" data-bs-dismiss="modal">
              {t('signUp.ok')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
