import { connect } from 'react-redux';
import { termsAndConditionSet } from '../actions';

const mapDispatchToProps = (dispatch) => ({
  setTermsAndCondition: (data) => {
    dispatch(termsAndConditionSet(data));
  }
});

const mapStateToProps = (state) => ({
  termsAndCondition: state.termsAndCondition ? state.termsAndCondition : null
});

export default connect(mapStateToProps, mapDispatchToProps);
