import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import withLocale from '../redux/Hoc/withLocale';
import withTermsAndCondition from '../redux/Hoc/withTermsAndCondition';

const TermConditions = ({ locale, termsAndCondition }) => {
  const { t } = useTranslation();
  const [termsCondition, setTermsCondition] = useState();

  useEffect(() => {
    setTermsCondition(termsAndCondition[locale]);
  }, [locale, termsAndCondition]);

  return (
    <div className="main-section">
      <section className="top-pick-section">
        <div className="container">
          <div className="toppick-block terms-and-condition">
            <h2 className="section-title">{t('layout.t&c')}</h2>
          </div>
          <p dangerouslySetInnerHTML={{ __html: termsCondition }} />
        </div>
      </section>
    </div>
  );
};

export default withLocale(withTermsAndCondition(TermConditions));
