import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import reportWebVitals from './reportWebVitals';

import './i18n';

import 'react-toastify/dist/ReactToastify.css';
import 'react-activity/dist/library.css';
import './assets/css/plugin.css';
import './assets/css/custom.css';
import './assets/css/responsive.css';

import './assets/js/custom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AppRoutes />
  </BrowserRouter>
);

reportWebVitals();
